/**
 * Renders an toaster message
 * @property {string} content
 */

import React from 'react';

// Icons
import * as icons from '../ui/Icons';

const Toast = class Toast extends React.Component {
  constructor() {
    super();

    // Bind this to functions
    this.handleClose = this.handleClose.bind(this);

    // Set the initial state
    this.state = {
      exiting: false
    };
  }

  componentDidMount() {
    const _this = this;
    const delay = 5000;

    // Hide the toaster after a delay
    setTimeout(function(){
      _this.handleClose();
    }, delay);
  }

  handleClose() {
    const { AppStore } = this.props.store;

    this.setState({
      exiting: true
    });

    setTimeout(function(){
      AppStore.toast = null;
    }, 1000);
  }

  render() {
    const { content } = this.props;

    return (
      <aside
        className={`toast ${this.state.exiting ? 'toast--exiting' : ''}`}
      >
        <span className="toast__content">
          {content}
        </span>

        <button
          className="toast__close"
          onClick={this.handleClose}
        >
          <icons.times />

          <span className="meta">Dismiss</span>
        </button>
			</aside>
    )
  }
}

export default Toast;
