/**
 * Renders the homepage view
 */

import React from 'react';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import { Link } from 'react-router-dom';

// Translation
import translate from '../translate/Translate';

// Components
import DatePicker from '../common/DatePicker';
const Home = observer(class Home extends React.Component {
  constructor() {
    super();

    // Set the initial state
    // This is a little odd but since the view isn’t shown until
    // loading has completed, we can’t focus the main content until
    // after an update, and we don’t want it to refocus every update
    this.state = {
      hasFocused: false,
      selectedDate: null
    };
  }

  componentDidMount() {
    const { AppStore, DomainStore } = this.props.store;
    const { handleToken } = this.props;

    // Determine the domain
    if(!AppStore.token) {
      DomainStore.checkDomain(handleToken);
    }
  }

  componentDidUpdate() {
    // Set focus to the main page element
    if(!this.state.hasFocused && this.page) {
      this.page.focus();

      this.setState({
        hasFocused: true
      });
    }
  }

  /**
   * Set the selected date in state and list exams
   * 
   * @function handleDateSelect
   * @param {string} date
   */
  handleDateSelect = date => {
    const { ExamStore, ScheduleStore } = this.props.store;

    if (date) {
      this.setState({
        selectedDate: date
      });

      ExamStore.listExams(date);

      action(() => {
        ScheduleStore.user.dob = date;
      })();
    }
  }

  render() {
    const { store, translation } = this.props;
    const { selectedDate } = this.state;
    const { AppStore, DomainStore, ExamStore } = store;
    const { loading } = AppStore;
    const { domain, domainTitle } = DomainStore;
    const { exams } = ExamStore;

    return (
      <main
        className="page wrap"
        ref={(page) => this.page = page}
        tabIndex="-1"
        aria-labelledby="page-title"
      >
        <div className="box box--md box--solo">
          <h1 id="page-title" className="box__title box__title--alt">
            {translation.heading.replace('%domainTitle%', domainTitle)}
          </h1>


          <div className="box__content">
            {translation[domain] && translation[domain].intro &&
              <p>{translation[domain].intro}</p>
            }
          </div>

          {(!exams || !exams.length) &&
            <div className="box__section box__section--prominent">
              <DatePicker
                {...this.props}
                className="datepicker--center"
                errorMsg={translation.dob.error}
                hideTools
                id="home-dob"
                label={translation.dob.label}
                max={new Date()}
                min={new Date('01/01/1900')}
                monthsShown={1}
                navType="all"
                onChange={(id, date) => {
                  this.handleDateSelect(date);
                }}
                openToDate={new Date()}
                required
                submitBtn
              />
            </div>
          }

          {selectedDate && !loading && (!exams || !exams.length) &&
            <span className="no-results">
              {translation.dob.no_results}
            </span>
          }

          {exams.length > 0 &&
            <ul className="result-list">
              {exams.map(
                (exam) =>
                <li key={exam.sid}>
                  <article className="media media--alt">
                    <div className="media__caption">
                      <h2
                        id={`heading-${exam.sid}`}
                        className="media__title"
                      >
                        {exam.title}
                      </h2>

                      <p className="media__summary">{exam.description}</p>
                    </div>

                    <div className="media__content">
                      <Link
                        className="btn"
                        to={`/${domain}/schedule/${exam.sid}/type`}
                        aria-labelledby={`heading-${exam.sid}`}
                      >
                        {translation.schedule}
                      </Link>
                    </div>
                  </article>
                </li>
              )}
            </ul>
          }

          <aside className="callout">
            <h2 className="callout__title">
              {translation.reschedule.heading}
            </h2>

            <p>{translation.reschedule.body}</p>

            <Link
              className="callout__btn btn btn--outline"
              to={`/${domain}/modify-appointment`}
            >
              {translation.reschedule.button}
            </Link>
          </aside>
        </div>
      </main>
    )
  }
})

export default translate('Home')(Home);
