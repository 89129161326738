/**
 * Renders a datepicker dialog popup
 * 
 * Derived from W3C's WAI-ARIA Datepicker Dialog Example
 * Source: https://www.w3.org/TR/wai-aria-practices-1.1/examples/dialog-modal/datepicker-dialog.html
 */

import React from 'react';
import { observer } from 'mobx-react';

// Translation
import translate from '../translate/Translate';

// Helpers
import { isDescendant, isSameDate, isNotSameMonth } from '../../helpers/helpers';

// Dependencies
import { addMonths, endOfDay, isBefore, parseISO, startOfDay } from 'date-fns';

// Icons
import * as icons from '../ui/Icons';

const keyCode = {
  'TAB': 9,
  'ENTER': 13,
  'ESC': 27,
  'SPACE': 32,
  'PAGEUP': 33,
  'PAGEDOWN': 34,
  'END': 35,
  'HOME': 36,
  'LEFT': 37,
  'UP': 38,
  'RIGHT': 39,
  'DOWN': 40
};

const DatePicker = observer(class DatePicker extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      focusDate: null,
      grids: [],
      lastMessage: '',
      months: [],
      open: false,
      selectedDate: null
    };

    // Create refs
    this.control = React.createRef();
    this.input = React.createRef();
    this.dialog = React.createRef();
    this.prevYear = React.createRef();
    this.prevMonth = React.createRef();
    this.nextMonth = React.createRef();
    this.nextYear = React.createRef();
    this.monthYear = React.createRef();
    this.tbody = React.createRef();
    this.selectButton = React.createRef();
    this.closeButton = React.createRef();
    this.message = React.createRef();

    this._months = [];
    this._isMounted = false;
  }

  componentDidMount() {
    const { defaultDate, monthsShown, translation } = this.props;
    const date = this.getNearestActiveDate();
    const grids = [];
  
    this._months = translation.months;

    // Set up the appropriate number of date grids
    if(monthsShown && monthsShown > 1) {
      for(let i = 0; i < monthsShown; i++) {
        grids.push({
          cells: []
        });
      }
    } else {
      grids.push({
        cells: []
      });
    }

    this.setState({
      focusDate: new Date(date),
      grids,
      selectedDate: defaultDate ? new Date(date) : null
    }, () => {
      this.initCells();
      if(defaultDate) {
        this.setTextboxDate(date);
      }
    });

    document.addEventListener('click', this.close);
    document.addEventListener('keydown', e => this.handleKeyDown(e));
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    const grids = [...this.state.grids];
    const { openDates } = this.props;

    if(prevProps.openDates !== openDates) {
      grids.forEach(grid => {
        grid.cells.forEach(cell => {
          // Set the date to UTC
          const formattedDate = `${startOfDay(cell.date).toISOString().substr(0, 10)}T00:00:00.000Z`

          // Check if the date is open
          const closed = openDates.indexOf(formattedDate) < 0;
          cell.closed = closed;
        });
      });

      this.setState({
        grids
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.close);
    document.removeEventListener('keydown', this.handleKeyDown);
    this._isMounted = false;
  }

  initCells = () => {
    const { id } = this.props;
    const grids = [...this.state.grids];
    let index = 0;
    let cells = [];

    for (var i = 0; i < 6; i++) {
      for (var j = 0; j < 7; j++) {
        const cell = {
          index,
          id: `date-btn-${id}-${index}`,
          row: i,
          column: j,
          date: new Date(),
          disabled: false,
          selected: false
        }
        cells.push(cell);
        index++;
      }
    }
    
    for(let i = 0; i < grids.length; i++) {
      grids[i].cells = cells;
    }

    this.setState({
      grids
    }, () => {
      this.updateGrid();
    });
  }

  handleChange = () => {
    const { id, onChange } = this.props;
    const { selectedDate } = this.state;
    const date = selectedDate ? new Date(selectedDate) : null;

    if(onChange) {
      const value = date ? date.toISOString() : null;
      onChange(id, value);
    }
  }

  /**
   * Update the dates displayed inside the calendar dialog
   * 
   * @param {boolean} focus - Optional flag to apply focus after updating
   * @param {object} grid
   */
  updateGrid = focus => {
    const { minDate, onRender } = this.props;
    let { focusDate, grids } = this.state;
    let i, flag;
    const months = [];

    for(let gridIndex = 0; gridIndex < grids.length; gridIndex++) {
      focusDate = addMonths(focusDate, gridIndex);

      const grid = grids[gridIndex];

      const fd = new Date(this.getNearestActiveDate(focusDate));
      const firstDayOfMonth = new Date(fd.getFullYear(), fd.getMonth(), 1);
      const dayOfWeek = firstDayOfMonth.getDay();
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() - dayOfWeek);
      const d = new Date(firstDayOfMonth);
  
      let cells = [];
  
      for(i = 0; i < grid.cells.length; i++) {
        flag = isNotSameMonth(fd, d);
        const newCell = this.updateCell(flag, d);
        cells.push({
          ...grid.cells[i],
          ...newCell
        });
  
        d.setDate(d.getDate() + 1); // Increase the date by 1 while iterating
      }
  
      grid.cells = cells;
  
      months.push(`${this._months[fd.getMonth()]} ${fd.getFullYear()}`)
    }


    this.setState({
      grids,
      months
    }, () => {
      if (focus) {
        this.applyFocus();
      }
    });

    if(onRender) {
      const lastGridIndex = grids.length - 1;
      const lastCellIndex = grids[lastGridIndex].cells.length - 1;

      let startDate = startOfDay(grids[0].cells[0].date).toISOString();

      if(isBefore(parseISO(startDate), minDate)) {
        startDate = startOfDay(minDate).toISOString();
      }

      const endDate = endOfDay(grids[lastGridIndex].cells[lastCellIndex].date).toISOString();

      onRender(startDate, endDate, this.applyFocus);
    }
  }

  /**
   * Apply keyboard focus to the focusDate element.
   */
  applyFocus = () => {
    const { focusDate } = this.state;

    const dateEl = document.querySelector(`[data-date="${startOfDay(focusDate).toISOString()}"]`);

    if(dateEl) {
      dateEl.tabIndex = 0;
      dateEl.focus();

      const oldDateEls = document.querySelectorAll('.datepicker__date-btn[tabindex="0"]:not(:focus), .datepicker__date-disabled[tabindex="0"]:not(:focus)');

      oldDateEls.forEach(oldDateEl => {
        oldDateEl.tabIndex = -1;
      });
    }
  }

  /**
   * Update the value of the current focusDate, & update the grid 
   * when the focusDate goes beyond current grid.
   * 
   * @param {Date} date - JavaScript Date object
   */
  updateFocusDate = date => {  
    const { min, max, translation } = this.props;
    const { focusDate } = this.state;
    const fd = new Date(focusDate);
    let changed = !isSameDate(fd, date);
    let updateGrid = false; 

    if(isNotSameMonth(fd, date)) {
      updateGrid = true;
    }

    // Check first if it’s out of bounds of min/max
    if(this.isPastMin(date)) {
      date = min;
      changed = true;
      updateGrid = true;
      const friendlyMin = this.formatFriendlyDateString(min.getFullYear(), min.getMonth(), min.getDate());
      this.setMessage(translation.min_help.replace('%date%', friendlyMin));
    } 

    if(this.isPastMax(date)) {
      date = max;
      changed = true;
      updateGrid = true;
      const friendlyMax = this.formatFriendlyDateString(max.getFullYear(), max.getMonth(), max.getDate());
      this.setMessage(translation.max_help.replace('%date%', friendlyMax));
    } 

    this.setState({
      focusDate: date
    }, () => {
      if(changed) {
        if(updateGrid) {
          this.updateGrid(true);
        } else {
          this.applyFocus();
        }
      }
    });
  }

  /**
   * Show/hide the calendar dialog
   */
  toggle = () => {
    const { open } = this.state;
    let updateGrid = false;

    if(!open) {
      updateGrid = true;
    }

    this.setState({
      open: !open
    }, () => {
      this.getDateInput(updateGrid);
    });
  }

  /**
   * Clear the textbox of its input, optional flag to hide the dialog
   * 
   * @param {boolean} hide
   */
  clear = hide => {
    const { open, selectedDate } = this.state;

    if(selectedDate !== null) {
      this.setDate();
    } else {
      this.setTextboxDate(null);
    }

    if(hide && open) {
      this.toggle();
    }

    this.setState({
      focusDate: this.getNearestActiveDate()
    });
  }

  /**
   * handleKeyDown
   * @param {e} event
   */
  handleKeyDown = e => {
    const { open } = this.state;

    switch(e.keyCode) {
      case keyCode.ESC:
        if(open) {
          this.toggle();
        }
        break;
        
      case keyCode.UP:
        this.moveFocus(-7);
        break;
      
      case keyCode.DOWN:
        this.moveFocus(7);
        break;

      case keyCode.LEFT:
        this.moveFocus(-1);
        break;

      case keyCode.RIGHT:
        this.moveFocus(1);
        break;

      case keyCode.HOME:
        this.moveFocusToFirstDayOfWeek();
        break;
  
      case keyCode.END:
        this.moveFocusToLastDayOfWeek();
        break;

      default:
        break;
    }
  }

  /**
   * Close the dialog
   */
  close = e => {
    const { open } = this.state;
    const { target } = e;

    if (!target) {
      return;
    }

    if (
      open && 
      !isDescendant(this.dialog.current, e.target) && 
      !isDescendant(this.control.current, e.target) && 
      !e.target.classList.contains('datepicker__control') &&
      this._isMounted
    ) {
      this.toggle();
    }
  }
  
  moveToNextYear = () => {
    const { focusDate } = this.state;
    const fd = new Date(focusDate);
    const newDate = new Date(fd.setFullYear(fd.getFullYear() + 1));
    this.updateFocusDate(newDate);
  }
  
  moveToPreviousYear = () => {
    const { focusDate } = this.state;
    const fd = new Date(focusDate);
    const newDate = new Date(fd.setFullYear(fd.getFullYear() - 1));
    this.updateFocusDate(newDate);
  }
  
  moveToNextMonth = () => {
    const { focusDate } = this.state;
    const fd = new Date(focusDate);
    let newDate;

    // If current focus is on a day that doesn't exist next month,
    // Set focus to the last numerical date available.
    if (fd.getDate() === 31 || (fd.getMonth() === 0 && fd.getDate() === 30)) {
      // Calculate the first day of two months ahead.
      const int_date = new Date(fd.getFullYear(), fd.getMonth() + 2, 1);
      // Then calculate one day prior to that intermediary date.
      newDate = new Date(int_date - 1);
    } else {
      newDate = new Date(fd.setMonth(fd.getMonth() + 1));
    }
    
    this.updateFocusDate(newDate);
  }
  
  moveToPreviousMonth = () => {
    const { focusDate } = this.state;
    const fd = new Date(focusDate);
    let newDate;

    // If current focus is on a day that doesn't exist in the previous
    // month, set focus to the last numerical date available.
    if (fd.getDate() === 31 || (fd.getMonth() === 2 && fd.getDate() === 30)) {
      // Calculate the first day of the current month.
      const int_date = new Date(fd.getFullYear(), fd.getMonth(), 1);
      // Then calculate one day prior to that intermediary date.
      newDate = new Date(int_date - 1);
    } else {
      newDate = new Date(fd.setMonth(fd.getMonth() - 1));
    }

    this.updateFocusDate(newDate);
  }

  moveFocus = amount => {
    const { focusDate } = this.state;
    const fd = new Date(focusDate);
    const newDate = new Date(fd.setDate(fd.getDate() + amount));
    this.updateFocusDate(newDate);
  }
  
  moveFocusToFirstDayOfWeek = () => {
    const { focusDate } = this.state;
    const fd = new Date(focusDate);
    const newDate = new Date(fd.setDate(fd.getDate() - fd.getDay()));
    this.updateFocusDate(newDate);
  }
  
  moveFocusToLastDayOfWeek = () => {
    const { focusDate } = this.state;
    const fd = new Date(focusDate);
    const newDate = new Date(fd.setDate(fd.getDate() + (6 - fd.getDay())));
    this.updateFocusDate(newDate);
  }
  
  /**
   * Display a date in the textbox in MM/DD/YYYY format
   * 
   * @param {Date} date - Optional - Date to display in textbox
   */
  setTextboxDate = date => {
    if (date) {
      let month = date.getMonth() + 1;
      month < 10 && (month = '0' + month);

      let day = date.getDate();
      day < 10 && (day = '0' + day);

      const value = `${month}/${day}/${date.getFullYear()}`;
      this.input.current.value = value;
    } else {
      this.input.current.value = '';
    }
  };

  isPastMin = date => {
    const { min } = this.props;

    if(date.getTime() === min.getTime()) {
      return false;
    }

    return min && date < min;
  }

  isPastMax = date => {
    const { max } = this.props;

    if(date.getTime() === max.getTime()) {
      return false;
    }

    return max && date > max;
  }

  getNearestActiveDate = date => {
    const { defaultDate, max, min } = this.props;
    let nearestActive = date || defaultDate || new Date();

    if (this.isPastMin(nearestActive)) {
      return min;
    }
    if (this.isPastMax(nearestActive)) {
      return max;
    }

    return nearestActive;
  }
  
  /**
   * Update focusDate and selectedDate with user input from the textbox
   * @param {boolean} flag - Flag to optionally re-render Grid
   */
  getDateInput = flag => {
    const { selectedDate } = this.state;
    const parts = this.input.current.value.split('/');

    if((parts.length === 3) &&
        Number.isInteger(parseInt(parts[0])) &&
        Number.isInteger(parseInt(parts[1])) &&
        Number.isInteger(parseInt(parts[2]))) {
      
      const date = new Date(parseInt(parts[2]), parseInt(parts[0]) - 1, parseInt(parts[1]));

      /**
       * Clear textbox if date input is outside of min/max bounds.
       */
      if (this.isPastMin(date) || this.isPastMax(date)) {
        this.clear();
      } else {
        if (!selectedDate || !isSameDate(date, selectedDate)) {
          this.setDate(date);
        }
      }
    } else {
      /**
       * If not a valid date MM/DD/YYYY, clear the textbox. 
       */
      this.clear();
    }

    if(flag) {
      this.updateGrid(true);
    }
  };
  
  /**
   * Format a human-readable date string for announcements via assistive technology.
   * 
   * @param {number} year
   * @param {number} month
   * @param {number} day
   */
  formatFriendlyDateString = (year, month, day) => {
    const { translation } = this.props;
    const { focusDate } = this.state;
    let selectedDate = null;

    if (typeof year !== 'number' || typeof month !== 'number' || typeof day !== 'number') {
      selectedDate = new Date(focusDate);
    }
    else {
      selectedDate = new Date(year, month, day);
    }

    const dayLabels = translation.days.map((day) => day.abbr);  
    let label = dayLabels[selectedDate.getDay()];
    label += ' ' + translation.months[selectedDate.getMonth()];
    label += ' ' + (selectedDate.getDate());
    label += ', ' + selectedDate.getFullYear();

    return label;
  };
  
  /**
   * Set a visually hidden, 'aria-live' announcement for screen readers
   * and other assistive technology
   * 
   * @param {string} str - Message to announce to the user
   */
  setMessage = str => {
    const { lastMessage } = this.state;

    function setMessageDelayed () {
      this.setState({
        lastMessage: str
      });
    }
  
    if (str !== lastMessage) {
      setTimeout(setMessageDelayed.bind(this), 200);
    }
  }

  /**
   * Apply a dynamic aria-label to the control button
   * 
   * @param {string} str
   */
  setLabel = str => {
    const { translation } = this.props;

    if (typeof str === 'string' && str.length) {
      str = ', ' + str;

    }
    this.control.current.setAttribute('aria-label', translation.choose + str);
  }

  /**
   * Set the value of the user's date selection & handle change.
   * 
   * @param {Date} date 
   */
  setDate = date => {
    let focusDate;
    let selectedDate;

    if (date) {
      focusDate = new Date(date);
      selectedDate = new Date(date);
      this.setTextboxDate(date);
    } else {
      const fallback = this.getNearestActiveDate();

      focusDate = new Date(fallback);
      selectedDate = null;
      this.setTextboxDate(null);
    }

    this.setState({
      focusDate,
      selectedDate
    }, () => {
      this.handleChange();
    });
  }

  /**
   * Returns a string value for the control button's aria-label
   */
  getDateLabel = () => {
    let label = '';

    const parts = this.input.current.value.split('/');
  
    if ((parts.length === 3) &&
        Number.isInteger(parseInt(parts[0])) &&
        Number.isInteger(parseInt(parts[1])) &&
        Number.isInteger(parseInt(parts[2]))) {
      const month = parseInt(parts[0]) - 1;
      const day = parseInt(parts[1]);
      const year = parseInt(parts[2]);
  
      label = this.formatFriendlyDateString(year, month, day);
    }
  
    return label;
  }

  handleControlFocus = () => {
    const dateLabel = this.getDateLabel();

    if (dateLabel) {
      this.setLabel('selected date is ' + dateLabel);
    }
    else {
      this.setLabel('');
    }
  }

  /**
   * Update data within the scope of a single day cell
   * 
   * @param {boolean} disabled - Is this date on the calendar disabled?
   * @param {Date} date - JS Date object of the date in the calendar cell
   */
  updateCell = (disabled, date) => {
    const newDate = new Date(date);

    let d = newDate.getDate().toString();
    if(newDate.getDate() <= 9) {
      d = '0' + d;
    }
  
    let m = newDate.getMonth() + 1;
    if(newDate.getMonth() < 9) {
      m = '0' + m;
    }

    const full = newDate.getFullYear() + '-' + m + '-' + d;

    // Update the value of 'disabled' with respect to min & max:
    if(this.isPastMin(date) || this.isPastMax(date)) {
      disabled = true;
    }

    return {
      date: newDate,
      dataFormat: full,
      disabled
    }
  };

  handleDayMouseDown = (event, cell) => {
    const { disabled, date } = cell;

    if(!disabled) {
      this.setDate(date);
      this.toggle();
    }
  
    event.stopPropagation();
    event.preventDefault();
  }

  handleDayFocus = () => {
    const { cursor_help } = this.props.translation;
    this.setMessage(cursor_help);
  }

  /**
   * Render a 6x7 grid of daypicker button cells
   */
  renderRow = (grid, i) => {
    const { selectedDate } = this.state;

    return (
      <tr key={i}>
        {grid.cells.map((cell, j) => {
          if(cell.row === i) {
            return (
              <td key={j}>
                {!cell.disabled && !cell.closed &&
                  <button
                    id={cell.id}
                    type="button"
                    className={`datepicker__date-btn${selectedDate && selectedDate.getTime() === cell.date.getTime() ? ' selected' : ''}`}
                    onClick={(e) => this.handleDayMouseDown(e, cell)}
                    onFocus={this.handleDayFocus}
                    data-date={cell.date.toISOString()}
                    tabIndex="-1"
                  >
                    {cell.date.getDate()}
                  </button>
                }

                {(cell.disabled || cell.closed) &&
                  <span
                    id={cell.id}
                    className="datepicker__date-disabled"
                    onFocus={this.handleDayFocus}
                    data-date={cell.date.toISOString()}
                    tabIndex="-1"
                  >
                    {cell.date.getDate()}
                  </span>
                }
              </td>
            )
          }

          return null;
        })}
      </tr>
    )
  }

  updateFirstDate = () => {
    const firstDateBtn = document.querySelector('.datepicker__date-btn');

    if(firstDateBtn) {
      firstDateBtn.tabIndex = 0;
    }
  }

  render() {
    const {
      className,
      errorMsg,
      hideTools,
      id,
      inline,
      label,
      navType,
      submitBtn,
      translation
    } = this.props;
    const { focusDate, grids, lastMessage, months, open } = this.state;
    const rows = [];

    let rowIndex = 0;

    for(rowIndex = 0; rowIndex < 6; rowIndex++) {
      rows.push(rowIndex);
    }

    return (
      <div
        id={`datepicker--${id}`}
        className={`datepicker${
          inline ? ' datepicker--inline' : ''
        }${
          className ? ` ${className}` : ''
        }`}
      >
        <label
          htmlFor={`datepicker-input--${id}`}
          className={`datepicker__lbl form__lbl${inline ? ' meta' : ''}`}
        >
          {label || "Date"}
        </label>
        
        <div className="datepicker__textbox">
          <input 
            aria-autocomplete="none"
            className={`datepicker__input${
              submitBtn ? ' datepicker__input--connected' : ''
            }`}
            data-errormsg={errorMsg}
            id={`datepicker-input--${id}`}
            onBlur={this.getDateInput}
            onInput={event => {
              const { value } = event.target;

              if (value.length === 2 || value.length === 5) {
                event.target.value += '/';
              }
            }}
            onKeyDown={event => {
              if (
                !isFinite(event.key) &&
                event.key !== 'Backspace' &&
                !event.ctrlKey &&
                !event.metaKey
              ) {
                event.preventDefault();
              }

              if (event.key === 'Enter') {
                this.getDateInput();
              }
            }}
            maxLength="10"
            pattern="\d{1,2}\/\d{1,2}\/\d{4}"
            placeholder="mm/dd/yyyy"
            ref={this.input}
            type="text"
          />

          {!inline &&
            <button 
              id={`datepicker-control--${id}`}
              className={`datepicker__control${
                submitBtn ? ' datepicker__control--connected' : ''
              }`}
              type="button"
              ref={this.control}
              aria-label={translation.choose}
              onClick={this.toggle}
              onFocus={this.handleControlFocus}
            >
              <icons.calendar />
            </button>
          }

          {/*
            NOTE: This is a total placebo button because
            just by blurring the input field after entering
            a date, the change callback function will fire,
            thus “submitting” the datepicker. 
          */}
          {submitBtn &&
            <button
              className="datepicker__submit btn"
              type="button"
            >
              Submit
            </button>
          }
        </div>

        <section 
          id={`datepicker-dialog--$${id}`}
          className={`datepicker__dialog${open ? ' datepicker__dialog--visible' : ''}`}
          ref={this.dialog}
          role="dialog"
          aria-modal="true"
          aria-labelledby={`datepicker-heading--${id}`}
        >
          <header className="datepicker__dialog-head">
            <h2 
              id={`datepicker-heading-${id}`}
              ref={this.monthYear}
              className="datepicker__month"
              aria-live="polite"
            >
              {months.map((month, i) =>
                <span key={i}>{month}</span>
              )}
            </h2>

            <ul className="datepicker__nav">
              {(!navType || navType === 'year' || navType === 'all') &&
                <li>
                  <button
                    ref={this.prevYear}
                    type="button"
                    className="datepicker__nav-btn datepicker__nav-btn--prev-year"
                    aria-label={translation.prev_year}
                    onClick={this.moveToPreviousYear}
                  >
                    <icons.chevronLeftDouble />
                  </button>
                </li>
              }

              {(!navType || navType === 'month' || navType === 'all') &&
                <li>
                  <button
                    ref={this.prevMonth}
                    type="button"
                    className="datepicker__nav-btn datepicker__nav-btn--prev-month"
                    aria-label={translation.prev_month}
                    onClick={this.moveToPreviousMonth}
                  >
                    <icons.chevronLeft />
                  </button>
                </li>
              }

              {(!navType || navType === 'month' || navType === 'all') &&
                <li>
                  <button 
                    ref={this.nextMonth}
                    type="button"
                    className="datepicker__nav-btn datepicker__nav-btn--next-month"
                    aria-label={translation.next_month}
                    onClick={this.moveToNextMonth}
                  >
                    <icons.chevronRight />
                  </button>
                </li>
              }

              {(!navType || navType === 'year' || navType === 'all') &&
                <li>
                  <button 
                    ref={this.nextYear}
                    type="button"
                    className="datepicker__nav-btn datepicker__nav-btn--next-year"
                    aria-label={translation.next_year}
                    onClick={this.moveToNextYear}
                  >
                    <icons.chevronRightDouble />
                  </button>
                </li>
              }
            </ul>
          </header>

          <div className="datepicker__grid-wrap">
            {grids.map((grid, i) =>
              <table
                id={`datepicker-grid-${id}-${i}`}
                key={i}
                className="datepicker__grid"
                role="grid"
                aria-labelledby={`datepicker-heading-${id}-${i}`}
              >
                <thead>
                  <tr>
                    {translation.days.map((day, j) =>
                      <th
                        key={j}
                        scope="col"
                        abbr={day.abbr}
                      >
                        {day.text}
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody ref={this.tbody}>
                  {rows.map((j) =>
                    this.renderRow(grid, j)
                  )}

                  {this.updateFirstDate()}
                </tbody>
              </table>
            )}
          </div>

          <div
            ref={this.message}
            className="message meta"
            aria-live="polite"
          >
            {lastMessage}
          </div>

          {!hideTools && 
            <footer className="datepicker__action">
              <button 
                className="btn btn--ghost"
                type="button"
                ref={this.closeButton}
                value="close"
                onClick={this.toggle}
              >
                {translation.close}
              </button>

              <button
                className="btn btn--ghost"
                type="button"
                ref={this.clearButton}
                value="clear"
                onClick={this.clear}
              >
                {translation.clear}
              </button>

              <button
                type="button"
                ref={this.selectButton}
                className="btn btn--ghost"
                value="select"
                onClick={() => this.setDate(focusDate)}
              >
                {translation.select}
              </button>
            </footer>
          }
        </section>
      </div>
    )
  }
})

export default translate('DatePicker')(DatePicker);
