import { action, observable } from 'mobx';
import { tokens } from '../config';

// AppStore (used for loading, error throwing, etc.)
import AppStore from './AppStore';

// Helpers
import { getUrlSegment } from '../helpers/helpers';

let obx = observable({
  /*-------------------------------------------
    Domain
  -------------------------------------------*/
  domains: [
    {
      title: 'DC DMV',
      id: 'dc'
    }
  ],
  domain: null,
  domainTitle: null,
  documentTitle: null,

  /**
   * resetDomain - Clears out all stored domain data
   */
  resetDomain: action(function(){
    obx.domain = null;
    obx.domainTitle = null;
    obx.documentTitle = null;

    // Remove domain-specific styles and favicon
    const stylesheet = document.querySelector('#domain-styles');
    
    if(stylesheet) {
      stylesheet.remove();
    }

    const favicon = document.querySelector('#domain-favicon');

    if(favicon) {
      favicon.remove();
    }
  }),

  /**
   * checkDomain - Uses the current URL to determine which property we’re on
   * @param {function} callback
   */
  checkDomain: action(function(callback){
    const domain = getUrlSegment(1, window.location.pathname);

    switch(domain) {
      case 'dc':
        obx.domain = 'dc';
        obx.domainTitle = 'DC.gov';
        obx.documentTitle = 'DC.gov DMV Test Registration';
        break;

      default:
        obx.domain = 'sp';
        obx.domainTitle = 'SmarterProctoring';
        obx.documentTitle = 'SmarterProctoring DMV Test Registration';
    }

    // Load the correct stylesheet
    const existingStylesheet = document.querySelector('#domain-styles');

    if(!existingStylesheet) {
      const stylesheet = document.createElement('link');
      stylesheet.id = 'domain-styles';
      stylesheet.type = 'text/css';
      stylesheet.rel = 'stylesheet';
      stylesheet.media = 'screen';
      stylesheet.href = `/static/css/${obx.domain}.css`;
      document.head.appendChild(stylesheet);
    }

    // Load the correct favicon
    const existingFavicon = document.querySelector('#domain-favicon');

    if(!existingFavicon) {
      const favicon = document.createElement('link');
      favicon.id = 'domain-favicon';
      favicon.type = 'image/x-icon';
      favicon.rel = 'shortcut icon';
      favicon.href = `/static/img/favicons/${obx.domain}.ico`;
      document.head.appendChild(favicon);
    }

    // Set the document title
    obx.setDocumentTitle(obx.documentTitle);

    // Set the static token
    AppStore.token = tokens[obx.domain];

    if(callback) {
      callback();
    }
  }),

  /**
   * setDocumentTitle - Sets the document title (used after determining domain)
   */
  setDocumentTitle: function(title) {
    document.title = title;
  }
});

export default obx;
