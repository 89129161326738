/**
 * Renders an accessible date input field with an optional date picker for mouse users
 * @property {boolean} [disabled] - Disables the field
 * @property {string} id - ID attribute to be applied to the field
 * @property {boolean} [includeClear] - Shows or hides the clear date button
 * @property {object} [invalidDateMsg] - Content to show if an invalid date is selected
 * @property {string} [invalidDateMsg.heading] - Heading for the invalid date dialog box
 * @property {string} [invalidDateMsg.body] - Body for the invalid date dialog box
 * @property {string} [invalidDateMsg.button] - Button text for the invalid date dialog box
 * @property {object} [maxDate] - The maximum allowable date
 * @property {object} [minDate] - The minimum allowable date
 * @property {function} [onChange] - Callback function that gets fired when a valid date is selected
 * @property {object} [openToDate] - Opens the datepicker to the provided date
 * @property {boolean} [srOnly] - Makes the field only available to screen readers
 * @property {boolean} [inline] - Renders the calendar datepicker inline
 */

import React from 'react';
import { observer } from 'mobx-react';

// Translation
import translate from '../translate/Translate';

// Dependencies
import { format, getYear, isLeapYear, parseISO, subYears } from 'date-fns';

// Components
import DatePicker from '../common/DatePicker';
import AppStore from "../../stores/AppStore";

const DateSelect = observer(class DateSelect extends React.Component {
  constructor(props) {
    super(props);

    // Set the initial state
    this.state = {
      month: '',
      day: '',
      year: '',
      date: null
    }
  }

  componentDidMount() {
    const { defaultValue } = this.props;
    const { AppStore } = this.props.store;

    if(defaultValue) {
      this.setState({
        month: format(parseISO(defaultValue), 'M'),
        day: format(parseISO(defaultValue), 'd'),
        year: format(parseISO(defaultValue), 'yyyy'),
        date: defaultValue
      });
    }

    AppStore.getCurrentTime();
  }

  /**
   * renderMonths - Renders month options
   */
  renderMonths() {
    const { translation } = this.props;
    const options = [];

    for(let i = 1; i <= 12; i++) {
      options.push(<option value={i} key={i}>{translation.months[`${i}`]}</option>);
    }

    return options;
  }

  /**
   * renderDays - Renders day options
   */
  renderDays() {
    let daysInMonth = 31;

    if(
      this.state.month === '4' ||
      this.state.month === '6' ||
      this.state.month === '9' ||
      this.state.month === '11'
    ) {
      daysInMonth = 30;
    } else if(this.state.month === '2') {
      if(isLeapYear(new Date(this.state.year, 2, 1))) {
        daysInMonth = 29;
      } else {
        daysInMonth = 28;
      }
    }

    const options = [];

    for(let i = 1; i <= daysInMonth; i++) {
      options.push(<option value={i} key={i}>{i}</option>);
    }

    return options;
  }

  /**
   * renderYears - Renders year options, 5 years in the past and 0 in the future
   */
  renderYears() {
    let yearOffset = 0;

    if(this.props.yearOffset) {
      yearOffset = this.props.yearOffset;
    }

    let today = new Date();

    if(yearOffset) {
      today = subYears(today, yearOffset);
    }

    const thisYear = getYear(today);
    let options = [];
    let yearsPast = 5;
    let yearsFuture = 1;

    if(this.props.yearsPast) {
      yearsPast = this.props.yearsPast;
    }

    if(this.props.yearsFuture) {
      yearsFuture = this.props.yearsFuture;
    }

    for(let i = thisYear - yearsPast; i <= thisYear + yearsFuture; i++) {
      options.push(<option value={i} key={i}>{i}</option>);
    }

    options = options.reverse();

    return options;
  }

  /**
   * handleInput - Handles user input and fires the provided onChange function if input is valid
   * @param {date} date
   */
  handleInput = date => {
    const { onChange } = this.props;

    if(onChange) {
      onChange(date);
    }
  }

  /**
   * clearDate - Clears out the date field
   * @param {event} e
   */
  clearDate(e) {
    const { onChange } = this.props;

    this.setState({
      month: '',
      day: '',
      year: '',
      date: null
    });

    if(onChange) {
      onChange(null, e);
    }
  }

  render() {
    const {
      hideDatePicker,
      id,
      inline,
      onRender,
      openToDate,
      minDate
    } = this.props;

    if (AppStore.currentTime) {
      const tomorrow = new Date(AppStore.currentTime);
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0);
      tomorrow.setMinutes(0);
      tomorrow.setSeconds(0);
      tomorrow.setMilliseconds(0);

      return (
          <div className="date-select">
            {!hideDatePicker &&
            <div
                className={`date-select__picker ${inline ? 'date-select__picker--inline' : ''}`}
                aria-hidden="true"
            >
              <DatePicker
                  {...this.props}
                  hideTools
                  id={id}
                  label="From Date"
                  max={new Date('01/01/2070')}
                  min={minDate ? minDate : tomorrow}
                  monthsShown={inline ? 2 : 1}
                  navType="month"
                  onChange={(id, date) => this.handleInput(date)}
                  onRender={onRender}
                  openToDate={openToDate}
              />
            </div>
            }
          </div>
      )
    } else {
      return null;
    }
  }
})

export default translate('DateSelect')(DateSelect);
