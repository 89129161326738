/**
 * Renders the payment view using Stripe
 */

import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

// Config
import { config } from '../../../config';

// Helpers
import { checkValidity } from '../../../helpers/helpers';

// Translation
import translate from '../../translate/Translate';

// Dependencies
import currencyFormatter from 'currency-formatter';

const Payment = observer(class Payment extends React.Component {
  constructor() {
    super();

    // Bind this to functions
    this.handleSubmit = this.handleSubmit.bind(this);
    this.stripeTokenHandler = this.stripeTokenHandler.bind(this);
    this.complete = this.complete.bind(this);
  }

  componentDidMount() {
    const _this = this;
    const { history, store } = this.props;
    const { ScheduleStore } = store;
    this.stripe = window.Stripe(config.stripeApiKey);
    const elements = this.stripe.elements();

    // Send back to type select if no scheduling module is set
    if(!ScheduleStore.schedulingModule) {
      history.push('type');
    }

    // Make sure payment token has been reset
    ScheduleStore.paymentToken = null;

    // Create the Stripe card element
    const style = {
      base: {
        color: '#444',
        fontFamily: '"Roboto", sans-serif',
        fontSize: '14px',
        lineHeight: '35px',
        '::placeholder': {
          color: '#aaaa'
        }
      }
    };

    this.card = elements.create('card', { style: style });
    this.card.mount('#payment-card');

    // Listen for errors
    this.card.addEventListener('change', function(e){
      if(e.error) {
        _this.handleError(e.error.message);
      } else {
        const existingError = document.querySelector('#payment-error');

        if(existingError) {
          existingError.remove();
        }
      }
    });
  }

  /**
   * handleError - Handles Stripe errors
   * @param {string} error
   */
  handleError(error) {
    const cardHolder = document.querySelector('#payment-card');
    const errorEl = document.createElement('label');

    console.error(error);

    errorEl.innerHTML = error;
    errorEl.id = 'payment-error';
    errorEl.className = 'form__err';
    errorEl.setAttribute('for', cardHolder.id);

    cardHolder.parentNode.insertBefore(errorEl, cardHolder.nextSibling);
  }

  /**
   * handleSubmit - Creates a Stripe token
   * @param {event} e
   */
  handleSubmit(e) {
    e.preventDefault();

    // Add a class and check it to make sure the payment only gets submitted once
    if(e.target.className.indexOf('payment-submitted') < 0) {
      e.target.className += ' payment-submitted';

      this.stripe.createToken(this.card)
        .then(response => {
          if(response.error) {
            // Let the user know there was an error
            this.handleError(response.error.message);

            const form = document.querySelector('.payment.payment-submitted');
            form.className = form.className.replace(' payment-submitted', '');
          } else {
            // Send the token
            this.stripeTokenHandler(response.token);
          }
        })
    }
  }

  /**
   * stripeTokenHandler - Stores the Stripe token and calls the schedule endpoint
   * @param {object} token
   */
  stripeTokenHandler(token) {
    const { ScheduleStore } = this.props.store;

    // Store the payment token
    ScheduleStore.paymentToken = token;

    // Schedule the session
    ScheduleStore.scheduleSession(this.complete);
  }

  /**
   * complete - Moves the user forward to the scheduling completed message
   */
  complete() {
    const { history } = this.props;

    history.push('complete');
  }

  render() {
    const { cancellationPolicyUrl } = config;
    const { translation } = this.props;
    const { ScheduleStore } = this.props.store;
    const { currency, proctorLocation } = ScheduleStore;
    let userTotal = '';
    let fees = {};

    if(proctorLocation) {
      fees = ScheduleStore.proctorLocation.fees;
    }

    if(fees.userTotal) {
      userTotal = `${currencyFormatter.format(fees.userTotal, {code: currency})} ${currency}`;
    }

    return (
      <div className="box box--md box--solo">
        <Link
          className="back box__back"
          to="user-information"
        >
          {translation.back}
        </Link>

        <h1 className="box__title">
          {translation.heading}
        </h1>

        <form
          className="form payment"
          onSubmit={(e) => checkValidity(e, this.handleSubmit)}
          noValidate
        >
          {/* Hidden input to hold the payment token */}
          <input
            id="payment-token"
            type="hidden"
          />

          <ul className="form__fields">
            <li>
              <label htmlFor="payment-name">{translation.name.label}</label>

              <input
                type="text"
                id="payment-name"
                className="_lr-hide"
                defaultValue={`${ScheduleStore.user.firstName} ${ScheduleStore.user.lastName}`}
                data-errormsg={translation.name.error}
              />
            </li>
            <li>
              <label htmlFor="payment-card">{translation.card.label}</label>

              <div
                id="payment-card"
                className="payment__fields _lr-hide"
              >
              </div>
            </li>
          </ul>

          <button
            className="btn"
            type="submit"
          >
            {translation.submit.replace('%amount%', userTotal)}
          </button>
          <a
            className="btn--link btn--right"
            href={cancellationPolicyUrl}
            target="_blank"
            rel='noopener noreferrer'
            aria-label={translation.cancellation_policy_aria}
            >
            {translation.cancellation_policy}
          </a>
        </form>
      </div>
    )
  }
})

export default translate('Payment')(Payment);