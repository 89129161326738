/**
 * Renders a proctor location list item
 */

import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

// Helpers
import { checkValidity, generateGuid } from '../../../helpers/helpers';

// Translation
import translate from '../../translate/Translate';

// Dependencies
import { format, parseISO } from 'date-fns';

// Components
import CountryCode from '../../common/CountryCode';
import DatePicker from '../../common/DatePicker';
const UserInfo = observer(class UserInfo extends React.Component {
	constructor() {
		super();

		// Bind this to functions
		this.handleDOBSelect = this.handleDOBSelect.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

		// Set the initial state
		this.state = {
			country: 'US',
			dob: null
		};
	}

	componentDidMount() {
		const { birthdate } = this.props.store.ScheduleStore.user;

		if(birthdate) {
			this.setState({
				dob: birthdate
			});
		}
	}

	/**
	 * handleDOBSelect - Saves the selected date to state
	 */
	handleDOBSelect(date) {
		this.setState({
			dob: date
		});
	}

	/**
	 * handleSubmit - Saves the user data to store and advances to the next step
	 */
	handleSubmit() {
		const { history } = this.props;
		const { ScheduleStore } = this.props.store;
		const { user } = ScheduleStore;

		let birthdate = this.state.dob;

		if (!birthdate && user.dob) {
			birthdate = user.dob;
		}

		ScheduleStore.user = {
			firstName: this.firstName.value,
			lastName: this.lastName.value,
			email: this.email.value,
			mobilePhone: this.phone.value,
			birthdate
		};

		ScheduleStore.externalAppointmentId = generateGuid();

		history.push('agreements');
	}

  render() {
		const { translation } = this.props;
		const { user } = this.props.store.ScheduleStore;

    return (
      <div className="box box--md box--solo">
				<Link
          className="back box__back"
          to="time"
        >
          {translation.back}
        </Link>

				<h1 className="box__title">
					{translation.heading}
				</h1>

				<form
					className="form"
					onSubmit={(e) => checkValidity(e, this.handleSubmit)}
					noValidate
				>
					<ul className="form__fields">
						<li className="field--half">
							<label htmlFor="user-fname">{translation.first_name.label}</label>

							<input
								id="user-fname"
								type="text"
								defaultValue={user.firstName}
								ref={(input) => this.firstName = input}
								required
								aria-required="true"
								data-errormsg={translation.first_name.error}
							/>
						</li>
						<li className="field--half">
							<label htmlFor="user-lname">{translation.last_name.label}</label>

							<input
								id="user-lname"
								type="text"
								defaultValue={user.lastName}
								ref={(input) => this.lastName = input}
								required
								aria-required="true"
								data-errormsg={translation.last_name.error}
							/>
						</li>
						<li>
							<label htmlFor="user-dob">{translation.dob.label}</label>

							{user.dob && format(parseISO(user.dob), 'MM/dd/yyyy')}
							{!user.dob && this.state.dob && format(parseISO(this.state.dob), 'MM/dd/yyyy')}

							{!user.dob && !this.state.dob &&
								<DatePicker
									{...this.props}
									errorMsg={translation.dob.error}
									hideTools
									id="user-dob"
									label={translation.dob.label}
									max={new Date()}
									min={new Date('01/01/1900')}
									monthsShown={1}
									navType="all"
									onChange={(id, date) => {
										this.handleDOBSelect(date);
									}}
									openToDate={new Date()}
									required
								/>
							}
						</li>
						<li>
							<label htmlFor="user-email">{translation.email.label}</label>

							<input
								id="user-email"
								type="text"
								defaultValue={user.email}
								ref={(input) => this.email = input}
								required
								aria-required="true"
								data-errormsg={translation.email.error}
							/>
						</li>
						<li className="field--multi">
							<label htmlFor="user-phone">{translation.phone.label}</label>

							<label
								className="select"
								htmlFor="user-country-code"
							>
								<CountryCode
									id="user-country-code"
									onChange={(e) => this.setState({ country: e.target.value })}
								/>
							</label>

							<input
								id="user-phone"
								type="tel"
								defaultValue={user.mobilePhone}
								ref={(input) => this.phone = input}
								onChange={() => this.phone.setCustomValidity('')}
								required
								aria-required="true"
								data-errormsg={translation.phone.error}
								data-country={this.state.country}
							/>
						</li>
					</ul>

					<button
						className="btn"
						type="submit"
					>
						{translation.submit}
					</button>
				</form>
			</div>
    )
  }
})

export default translate('UserInfo')(UserInfo);