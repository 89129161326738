/**
 * Renders a view to select the proctor modality
 */

import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

// Helpers
import { getUrlSegment } from '../../../helpers/helpers';

// Translation
import translate from '../../translate/Translate';

// Dependencies
import { FeatureFlag } from 'react-launch-darkly';
const ProctorType = observer(class ProctorType extends React.Component {
  handleProctorType = (schedulingModule) => {
    const { history, store } = this.props;
    const { ScheduleStore } = store;

    ScheduleStore.schedulingModule = schedulingModule;

    if(schedulingModule === 'in-person') {
      history.push('geolocate');
    } else {
      const callback = () => {
        history.push('time');
      }

      ScheduleStore.searchProctorU(callback);
    }
  }

  /**
   * Render the schedule remote button
   * 
   * @function renderRemoteButton
   * @param {object} modality
   */
  renderRemoteButton = modality => {
    return (
      <button
        className="option__btn btn btn--outline"
        onClick={() => this.handleProctorType(modality.proctor_type)}
        type="button"
      >
        {modality.button}
      </button>
    )
  }

  /**
   * Render the remote coming soon message
   * 
   * @function renderRemoteComingSoon
   */
   renderRemoteComingSoon = modality => {
    return (
      <React.Fragment>
        <span className="option__btn btn btn--disabled">
          Coming soon!
        </span>

        <span className="no-results">
          <a
            href="https://smarterservices.typeform.com/to/YYnNRxO4"
            target="_blank"
            rel="noreferrer noopener"
          >
            Notify me when it’s ready.
          </a>
        </span>
      </React.Fragment>
    )
  }

  render() {
    const { translation } = this.props;
    const { pathname } = this.props.location;

    return (
      <div className="box box--lg box--solo">
        <Link
          className="back box__back"
          to={`/${getUrlSegment(1, pathname)}`}
        >
          {translation.back}
        </Link>

        <h1 className="box__title">
          {translation.heading}
        </h1>

        <ul className="option-list">
          {translation.modalities.map((modality, i) =>
            <li key={i}>
              <article className="option">
                <div className="option__content">
                  <h2 className="option__title">
                    {modality.heading}
                  </h2>

                  <div dangerouslySetInnerHTML={{__html: modality.body}} />
                </div>

                {modality.note &&
                  <p className="note">{modality.note}</p>
                }

                {modality.proctor_type === 'in-person' &&
                  <button
                    className="option__btn btn btn--outline"
                    onClick={() => this.handleProctorType(modality.proctor_type)}
                    type="button"
                  >
                    {modality.button}
                  </button>
                }

                {modality.proctor_type !== 'in-person' &&
                  <FeatureFlag
                    flagKey="remote-dmv-proctoring"
                    renderFeatureCallback={() => this.renderRemoteButton(modality)}
                    renderDefaultCallback={this.renderRemoteComingSoon}
                  />
                }
              </article>
            </li>
          )}
        </ul>
      </div>
    )
  }
})

export default translate('ProctorType')(ProctorType);