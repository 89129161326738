/**
 * Renders an address
 * @property {object} location
 */

import React from 'react';

const Address = class Address extends React.Component {
  render() {
    const {
			address1,
			address2,
			place,
			postalCode,
			region
		} = this.props.location;

    return (
      <p className="adr">
				{address1 &&
					<span className="adr__street">{address1}</span>
				}

				{address2 &&
					<span className="adr__street">{address2}</span>
				}
				
				{place &&
					<span className="adr__locality">{place},</span>
				}

				{region &&
					<span className="adr__region">{region}</span>
				}

				{postalCode &&
					<span className="adr__postal">{postalCode}</span>
				}
			</p>
    )
  }
}

export default Address;
