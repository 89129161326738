import {api, config} from './../config';

// Dependencies
import { parsePhoneNumberFromString } from 'libphonenumber-js';

/**
 * storeData - Stores data in sessionStorage and falls back to cookies
 * @param {string} name
 * @param {string} data
 */
export function storeData(name, data) {
  try {
    sessionStorage.setItem(name, data);
  } catch(error) {
    document.cookie = name + '=' + data + '; expires=0; path=/';
  }
}

/**
 * getData - Gets data out of sessionStorage and falls back to cookies
 * @param {string} name
 */
export function getData(name) {
  try {
    return sessionStorage.getItem(name);
  } catch(error) {
    const match = document.cookie.match(new RegExp(name + '=([^;]+)'));

    if(match) {
      return match[1];
    } else {
      return null;
    }
  }
}

/**
* Get URL segment
* @param {integer} segment
* @param {string} pathname
**/
export function getUrlSegment(segment, pathname) {
  if (!pathname) pathname = window.location.pathname;

  let pathArray = pathname.split('/');

  if (pathArray[0] === '') {
    pathArray.splice(0, 1);
  }

  return pathArray[segment - 1];
}

/**
 * Get query params from a string
 * @export
 * @param {name} string
 * @param {url} string
 */
export function getParam(name, url) {
  if (!url) url = window.location.href;

  name = name.replace(/[[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  let results = regex.exec(url);

  if(!results) {
    return null;
  }

  if(!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

/**
 * checkValidity - Checks the validity of form fields on submit
 * @param {event} e
 * @param {function} callback
 */
export function checkValidity(e, callback) {
  e.preventDefault();

  const form = e.target;

  const telFields = form.querySelectorAll('input[type="tel"]');

  if(telFields.length) {
    telFields.forEach(field => {
      validatePhone(field);
    });
  }

  const invalidFields = form.querySelectorAll('input[required]:invalid, select[required]:invalid, textarea[required]:invalid, input[required][aria-invalid="true"]');

  // Mark the form as submitted
  form.className += ' form--submitted';

  // Remove any existing error messages
  const existingErrors = form.querySelectorAll('.form__err');

  for(let i = 0; i < existingErrors.length; i++) {
    existingErrors[i].remove();
  }

  // Handle validation
  if(invalidFields.length > 0) {
    // If there are invalid fields, insert error messages
    for(let i = 0; i < invalidFields.length; i++) {
      const errorMsg = invalidFields[i].getAttribute('data-errormsg');
      const errorEl = document.createElement('label');

      errorEl.innerHTML = errorMsg;
      errorEl.className = 'form__err';
      errorEl.setAttribute('for', invalidFields[i].id);
      errorEl.setAttribute('role', 'alert');

      invalidFields[i].parentNode.insertBefore(errorEl, invalidFields[i].nextSibling);
    }
  } else {
    if(callback) {
      callback(e);
    }
  }
}

/**
 * validatePhone
 * @param {element} field
 */
function validatePhone(field) {
  const tel = parsePhoneNumberFromString(field.value, field.dataset.country);

  if(!tel || !tel.isValid()) {
    field.setCustomValidity('Invalid phone number');
  }
}

/**
 * getMapUrl - Get static map url from google maps API for a given <latitude, longitude> pair
 * @export
 * @param {number} latitude
 * @param {number} longitude
 * @returns {string}
 */
export function getMapUrl(latitude, longitude) {
  return `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}` +
    `&zoom=15&scale=2&size=150x150&maptype=roadmap&key=${config.mapsApiKey}` +
    `&format=png&visual_refresh=true&markers=${latitude},${longitude}`;
}

/**
 * generateGuid
 * @export
 * @returns {string}
 */
export function generateGuid() {
  let guid = '';

  for(let i = 0; i < 32; i++) {
    if(i === 8 || i === 12 || i === 16 || i === 20) {
      guid += '-';
    }

    const digit = Math.floor(Math.random() * 16).toString(16).toUpperCase();

    guid += digit;
  }

  return guid;
}

/**
 * isDescendant - Checks if an element is a descendant of a given parent
 * @export
 * @param {parent} element
 * @param {child} element
 * @return boolean
 */
export function isDescendant(parent, child) {
  let node = child.parentNode;

  while(node !== null) {
    if(node === parent) {
      return true;
    }

    node = node.parentNode;
  }

  return false;
}

/**
 * isSameDate - Checks if two JS Date objects are the same day, month, & year
 * @param {Date} date1
 * @param {Date} date2
 */
export function isSameDate(date1, date2) {
  return (date1.getFullYear() === date2.getFullYear()) &&
        (date1.getMonth() === date2.getMonth()) &&
        (date1.getDate() === date2.getDate());
}

/**
 * isNotSameMonth - Checks if two JS Date objects belong in different months
 * @param {Date} date1
 * @param {Date} date2
 */
export function isNotSameMonth(date1, date2) {
  return (date1.getFullYear() !== date2.getFullYear()) ||
        (date1.getMonth() !== date2.getMonth());
}

export async function openExamDirect(installSid, examSid, sessionSid) {
  const url = async () =>  api.get(`/installs/${installSid}/exams/${examSid}/sessions/${sessionSid}/onboard`)
      .then(response => {
        return response.data.redirectUrl;
      })
      .catch((e) => {
        console.error(e);
      });
  return await url();
}
