// Import stores
import AppStore from './AppStore';
import DomainStore from './DomainStore';
import ExamStore from './ExamStore';
import ProctorStore from './ProctorStore';
import ScheduleStore from './ScheduleStore';

// Combine stores
const store = {
  AppStore,
  DomainStore,
  ExamStore,
  ProctorStore,
  ScheduleStore
};

export default store;
