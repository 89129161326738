/**
 * Renders a pop-up dialog box
 */

import React from 'react';
import { observer } from 'mobx-react';

const Dialog = observer(class Dialog extends React.Component {
  componentDidMount() {
    // Set focus to the dialog
    if(this.dialog) {
      this.dialog.focus();
    }
  }

  constructor() {
    super();

    // Set the initial state
    this.state = {
      option: null
    };
  }

  closeDialog(e) {
    e.preventDefault();
    this.props.store.AppStore.closeDialog()
  }

  render() {
    const { dialogContent, dialogSubmit, dialogType } = this.props.store.AppStore;

    return (
      <section
        className="dialog"
        role="alert"
        ref={(dialog) => this.dialog = dialog}
        tabIndex="-1"
      >
        <div className="dialog__content">
          <strong className="dialog__title">
            {dialogContent.heading}
          </strong>

          <p>{dialogContent.body}</p>

          {dialogType && dialogType === 'prompt' && dialogContent.options &&
            <label className="select" htmlFor="dialog-option">
              <select
                id="dialog-option"
                ref={(input) => this.option = input}
                onChange={() => this.setState({ option: this.option.value })}
              >
                <option value="">{dialogContent.helper}</option>

                {Object.keys(dialogContent.options).map(
                  (option, i) =>
                    <option value={option} key={i}>
                      {dialogContent.options[option]}
                    </option>
                )}
              </select>
            </label>
          }

          {/* Close dialog button */}
          {!dialogType &&
            <div className="dialog__action">
              <a
                href="#app"
                className="btn"
                onClick={(e) => this.closeDialog(e)}
              >
                {dialogContent.button}
              </a>
            </div>
          }

          {/* Submit/cancel prompt buttons */}
          {dialogType && dialogType === 'prompt' &&
            <div className="dialog__action">
              <button
                className="btn"
                type="button"
                onClick={() => dialogSubmit(this.state)}
              >
                {dialogContent.button}
              </button>

              <a
                href="#app"
                className="btn btn--outline"
                onClick={(e) => this.closeDialog(e)}
              >
                {dialogContent.cancel}
              </a>
            </div>
          }
        </div>
      </section>
    )
  }
})

export default observer(Dialog);
