/**
 * Renders the Schedule view shell and determines which step to show
 */

import React from 'react';
import { observer } from 'mobx-react';

// Helpers
import { getData, getParam, getUrlSegment } from '../../../helpers/helpers';

// Components
import ProctorType from './ProctorType';
import Geolocate from './Geolocate';
import SelectLocation from './SelectLocation';
import SelectTime from './SelectTime';
import UserInfo from './UserInfo';
import Agreements from './Agreements';
import Payment from './Payment';
import Complete from './Complete';

const Schedule = observer(class Schedule extends React.Component {
	constructor() {
		super();

		// Set the initial state
		this.state = {
			hasFocused: false,
			schedulingModule: null,
			view: 'type'
		};
	}

	componentDidMount() {
		const { schedulingModule } = this.state;
		const { history, location, reschedule } = this.props;
		const { ScheduleStore, ExamStore } = this.props.store;
		const { pathname } = this.props.location;
		const viewSegment = getUrlSegment(4, pathname);

		// If there is no token stored, redirect to the home view
		if(!getData('token')) {
			history.push(`/${getUrlSegment(1, pathname)}`);
		}

		// If we don’t have all the necessary data (i.e. ZIP code) direct back to the first step
		// But if we are rescheduling, we don’t need the ZIP
		if(!ScheduleStore.zipCode && viewSegment !== 'geolocate' && !reschedule && schedulingModule === 'in-person') {
			history.push('geolocate');
		} else if(reschedule && !ScheduleStore.examSession) {
			const examSid = getUrlSegment(3, location.pathname);
			const sessionSid = getParam('session', location.search);

			const callback = () => {
				this.getExamData();
			}
			ExamStore.listExams(null, callback);

			if(examSid && sessionSid) {
				ScheduleStore.getSession(examSid, sessionSid);
			}
		}

		// Get exam data
		this.getExamData();
	}

	componentDidUpdate(prevProps, prevState) {
		const { pathname } = this.props.location;
		
		// Get the examSid from the URL and store that exam as the one being scheduled
		this.getExamData();

		// Determine which view to show based on the URL
		const viewSegment = getUrlSegment(4, pathname);
		let view = 'type';

		switch(viewSegment) {
			case 'location':
				view = 'selectLocation';
				break;

			case 'time':
				view = 'selectTime';
				break;

			case 'user-information':
				view = 'userInfo';
				break;

			case 'agreements':
				view = 'agreements';
				break;

			case 'payment':
				view = 'payment';
				break;

			case 'complete':
				view = 'complete';
				break;

			case 'geolocate':
				view = 'geolocate';
				break;

			default:
				view = 'type';
		}

		if(view !== this.state.view) {
			this.setState({
				view
			});
		}

		// Set focus to the main page after initial load
		if(!this.state.hasFocused && this.page) {
			this.page.focus();
			
			this.setState({
				hasFocused: true
			});
    }

		// Set focus to the main page element after navigation
		if(prevState.view !== this.state.view) {
			if(this.page) {
				this.page.focus();
			}
		}
	}

	/**
	 * getExamData - Get the examSid from the URL and store that exam as the one being scheduled
	 */
	getExamData() {
		const { ExamStore, ScheduleStore } = this.props.store;
		const { pathname } = this.props.location;

		// Get the examSid from the URL and store that exam as the one being scheduled
		const examSid = getUrlSegment(3, pathname);

		if(!ScheduleStore.exam || ScheduleStore.exam.sid !== examSid) {
			ScheduleStore.setExam(examSid, ExamStore.exams);
		}
	}

  render() {
		const { ScheduleStore } = this.props.store;

		const Components = {
			type: ProctorType,
			geolocate: Geolocate,
			selectLocation: SelectLocation,
			selectTime: SelectTime,
			userInfo: UserInfo,
			agreements: Agreements,
			payment: Payment,
			complete: Complete
		};

		const View = Components[this.state.view];

		if(ScheduleStore.exam) {
			return (
				<main
					className="page wrap"
					ref={(page) => this.page = page}
          tabIndex="-1"
          aria-labelledby="page-title"
				>
					<View {...this.props} />
				</main>
			)
		}

		return null;
  }
})

export default Schedule;