/**
 * Renders a view to get the user’s location before searching
 */

import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

// Helpers
import { checkValidity } from '../../../helpers/helpers';

// Translation
import translate from '../../translate/Translate';

const Geolocate = observer(class Geolocate extends React.Component {
  handleSubmit = e => {
    const { history } = this.props;
    const { ProctorStore, ScheduleStore } = this.props.store;
    
    ScheduleStore.zipCode = this.zipCode.value;

    // Perform the proctor search using the ZIP code
    const callback = () => {
      history.push('location');
    }

    const data = {
      zipCode: ScheduleStore.zipCode,
      examSid: ScheduleStore.exam.sid
    }

    ProctorStore.proctorSearch(data, callback);
  }

  render() {
    const { translation } = this.props;

    return (
      <div className="box box--md box--solo">
        <Link
          className="back box__back"
          to="type"
        >
          {translation.back}
        </Link>

        <h1 className="box__title">
          {translation.heading}
        </h1>

        <form
          className="form form--inline"
          onSubmit={(e) => checkValidity(e, this.handleSubmit)}
          noValidate
        >
          <fieldset>
            <ul className="form__fields">
              <li>
                <label
                  className="form__lbl"
                  htmlFor="geolocate-zip"
                >
                  {translation.zip_code.label}
                </label>

                <input
                  id="geolocate-zip"
                  type="text"
                  ref={(input) => this.zipCode = input}
                  placeholder={translation.zip_code.helper}
                  aria-required="true"
                  required
                  data-errormsg={translation.zip_code.error}
                />
              </li>
            </ul>
          </fieldset>

          <button
            className="btn"
            type="submit"
          >
            {translation.submit}
          </button>
        </form>
      </div>
    )
  }
})

export default translate('Geolocate')(Geolocate);