/**
 * Renders a list of available time slots
 */

import React from 'react';
import { observer } from 'mobx-react';

// Translation
import translate from '../../translate/Translate';

// Dependencies
import { addMinutes, parseISO } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import AppStore from "../../../stores/AppStore";

const TimeSlots = observer(class TimeSlots extends React.Component {
  componentDidMount() {
    const { AppStore } = this.props.store;
    AppStore.getCurrentTime();
    // Set focus to the list of time slots
    if(this.slots) {
      this.slots.focus();
    }
  }

  /**
   * renderTimeRange - Renders the slots for a given time range
   *
   * @param {string} group - The group of time slots
   * @param {string} range - The time range (e.g. morning, afternoon, evening)
   */
  renderTimeRange(group, range) {
    const { slots, translation } = this.props;
    const { ScheduleStore, AppStore } = this.props.store;
    const { timeZone } = AppStore;
    const { duration } = ScheduleStore.exam.configuration;

    const in24Hours = new Date(AppStore.currentTime);
    in24Hours.setDate(in24Hours.getDate() + 1);

    return (
      <section
        className="box__section box__section--hidden"
        id={`time-slots-${range}`}
      >
        <h5 className="box__subhead">{translation[range]}</h5>

        <ul className="timeslots">
          {slots[group][range].map(
            (slot, i) => {
              const section = document.querySelector(`#time-slots-${range}`);

              if (new Date(slot.startDate) < in24Hours) {
                return null;
              } else if (section && section.classList.contains('box__section--hidden')) {
                // Don’t love this, but since we’re already in
                // a render, it is the only way to show/hide
                // the section without trying to manipulate state
                section.classList.remove('box__section--hidden');
              }

              return (
                <li key={i}>
                  <h6
                    id={`slot-${group}-${range}-${i}`}
                    className="timeslots__time"
                  >
                    {format(
                      utcToZonedTime(parseISO(slot.startDate), timeZone), 'h:mm aaaa'
                    )} – {format(
                      utcToZonedTime(
                        addMinutes(parseISO(slot.startDate), duration), timeZone
                      ),
                      'h:mm aaaa zzz'
                    )}

                    <span className="timeslots__date">
                      {format(utcToZonedTime(parseISO(slot.startDate), timeZone), 'eeee, MMMM d')}
                    </span>
                  </h6>

                  <button
                    className="btn btn--outline"
                    onClick={() => this.selectTime(slot)}
                    type="button"
                    aria-labelledby={`slot-${group}-${range}-${i}`}
                  >
                    {translation.schedule}
                  </button>
                </li>
              )
            }
          )}
        </ul>
      </section>
    )
  }

  /**
   * selectTime
   * @param {object} slot
   */
  selectTime(slot) {
    const { history, reschedule } = this.props;
    const { ScheduleStore } = this.props.store;

    ScheduleStore.appointmentDateTime = slot.startDate;

    // If examSession data exists, we are rescheduling
    // and can skip the user information and payment
    if(reschedule) {
      const callback = () => history.push('complete');

      ScheduleStore.rescheduleSession(callback);
    } else {
      history.push('user-information');
    }
  }

  render() {
    const { selectedDate, slots, translation } = this.props;

    if (AppStore.currentTime) {
      return (
          <div
              className="timeslots-wrap"
              ref={(slots) => this.slots = slots}
              tabIndex="-1"
              aria-labelledby="slots-heading"
          >
            <h2
                id="slots-heading"
                className="box__subheading"
            >
              {selectedDate ? format(parseISO(selectedDate), 'MMMM d, yyyy') : translation.heading}
            </h2>

            {Object.keys(slots).map(
                (group, i) =>
                    <div key={i}>
                      {/* Morning */}
                      {slots[group].morning.length > 0 &&
                      this.renderTimeRange(group, 'morning')
                      }

                      {/* Afternoon */}
                      {slots[group].afternoon.length > 0 &&
                      this.renderTimeRange(group, 'afternoon')
                      }

                      {/* Evening */}
                      {slots[group].evening.length > 0 &&
                      this.renderTimeRange(group, 'evening')
                      }
                    </div>
            )}
          </div>
      )
    } else {
      return null;
    }
  }
})

export default translate('TimeSlots')(TimeSlots);