import { action, observable, toJS } from 'mobx';
import { api, config } from '../config';

// AppStore - used for loading, error throwing, etc.
import AppStore from './AppStore';

// ExamStore - used to get exam data when rescheduling
import ExamStore from './ExamStore';

// Dependencies
import { addMinutes, format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

let obx = observable({
  /*-------------------------------------------
    Scheduling data
  -------------------------------------------*/
  exam: null,
  zipCode: null,
  proctorLocation: null,
  user: {},
  currency: 'USD',
  appointmentDateTime: null,
  externalAppointmentId: null,
  schedulingModule: null,

  /**
   * resetScheduleData - Clears out stored scheduling data
   */
  resetScheduleData: action(function(){
    obx.exam = null;
    obx.zipCode = null;
    obx.proctorLocation = null;
    obx.user = {};
    obx.appointmentDateTime = null;
    obx.externalAppointmentId = null;
  }),

  /**
   * setExam - Sets the selected exam from an array of exams
   * @param {sid} examSid
   * @param {array} exams
   */
  setExam: action(function(examSid, exams){
    let i = 0;

    while(!obx.exam && i < exams.length) {
      if(exams[i].sid === examSid) {
        obx.exam = exams[i];
      }

      i++;
    }
  }),

  /*-------------------------------------------
    Proctor availability
  -------------------------------------------*/
  openDates: [],
  openDatesLocationSid: null,
  openDatesStart: null,
  openDatesEnd: null,
  proctorAvailability: null,
  availabilityGroups: null,
  startDate: null,
  endDate: null,

  /**
   * getOpenDates - Checks which dates have openings for a proctor location
   */
  getOpenDates: action((startDate, endDate, callback, noLocationCallback) => {
    const { installSid } = AppStore.userData;
    let proctorLocationSid = null;

    if(obx.proctorLocation) {
      proctorLocationSid = obx.proctorLocation.location.sid;
    } else if(noLocationCallback) {
      // No proctor location is set (possibly due to refresh)
      // Send back to beginning
      noLocationCallback();
      return;
    }

    // Do nothing if we already have open dates
    // for this location and this date range11
    if(
      obx.openDates.length !== 0 &&
      obx.openDatesLocationSid === proctorLocationSid &&
      obx.openDatesStart === startDate &&
      obx.openDatesEnd === endDate
    ) {
      return;
    }

    AppStore.startLoading('getOpenDates');

    obx.openDates = [];
    obx.openDatesLocationSid = null;
    obx.openDatesStart = startDate;
    obx.openDatesEnd = endDate;

    api.get(`/installs/${installSid}/proctors/openDays?proctorLocationSids=${proctorLocationSid}&startDate=${startDate}&endDate=${endDate}&duration=${obx.exam.configuration.duration}`)
      .then(response => {
        obx.openDates = response.data.locations[0].daysAvailable;
        obx.openDatesLocationSid = response.data.locations[0].proctorLocationSid;

        AppStore.finishLoading('getOpenDates');

        if(callback) {
          callback();
        }
      })
      .catch(error => {
        AppStore.throwError();
      })
  }),

  /**
   * getProctorAvailability - Gets available times for a given date, location, and exam duration
   */
  getProctorAvailability: action(function(callback){
    AppStore.startLoading('getProctorAvailability');

    const { installSid } = AppStore.userData;
    const proctorLocationSid = obx.proctorLocation.location.sid;

    api.get(`/installs/${installSid}/proctors/availability?proctorLocationSids=${proctorLocationSid}&startDate=${obx.startDate}&endDate=${obx.endDate}&duration=${obx.exam.configuration.duration}`)
      .then(response => {
        obx.proctorAvailability = response.data;

        // Group the times
        obx.groupAvailabilityTimes(proctorLocationSid);

        AppStore.finishLoading('getProctorAvailability');

        if(callback) {
          callback();
        }
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  }),

  /**
   * groupAvailabilityTimes - Groups availability times into morning, afternoon, and evening
   */
  groupAvailabilityTimes: action(proctorLocationSid => {
    // Reset the groups
    obx.availabilityGroups = null;

    // Loop and group the times
    obx.proctorAvailability[proctorLocationSid].availability.forEach(time => {
      const date = utcToZonedTime(parseISO(time.startDate), AppStore.timeZone);
      const formattedDate = format(date, 'MMddyyyy');
      const hour = parseInt(format(date, 'H'), 10);

      // If no end date has been provided, add one
      if(!time.endDate) {
        time.endDate = addMinutes(date, obx.exam.configuration.duration).toISOString();
      }

      // Convert availability groups to an object
      if(!obx.availabilityGroups) {
        obx.availabilityGroups = {};
      }

      // If an object doesn’t already exist for this date, create one
      if(!obx.availabilityGroups[formattedDate]) {
        obx.availabilityGroups[formattedDate] = {
          morning: [],
          afternoon: [],
          evening: []
        };
      }

      // Push each time object to the correct array based on its hour value
      if(hour < 12) {
        obx.availabilityGroups[formattedDate].morning.push(time);
      } else if(hour < 18) {
        obx.availabilityGroups[formattedDate].afternoon.push(time);
      } else {
        obx.availabilityGroups[formattedDate].evening.push(time);
      }
    });
  }),

  /*-------------------------------------------
    Payment data
  -------------------------------------------*/
  paymentToken: null,

  /*-------------------------------------------
    Schedule session
  -------------------------------------------*/
  examSession: null,

  /**
   * scheduleSession
   * @param {function} callback
   */
  scheduleSession: action(function(callback){
    AppStore.startLoading('scheduleSession');

    const { installSid } = AppStore.userData;
    const { langauge } = AppStore.language
    const data = {
      user: toJS(obx.user),
      session: {
        examSid: obx.exam.sid,
        proctorLocationSid: obx.proctorLocation.location.sid,
        currency: obx.currency,
        langauge: langauge,
        amount: obx.proctorLocation.fees.userTotal,
        cardToken: obx.paymentToken.id,
        appointmentDateTime: obx.appointmentDateTime,
        externalAppointmentId: obx.externalAppointmentId
      }
    };

    data.user.currencyCode = obx.currency;
    data.user.timeZoneSid = AppStore.timeZoneSid;

    api.post(`/installs/${installSid}/sessions`, data)
      .then(response => {
        obx.examSession = response.data;

        if(callback) {
          callback();
        }

        AppStore.finishLoading('scheduleSession');
      })
      .catch(error => {
        AppStore.throwError(error);
      });
  }),

  /*-------------------------------------------
    Reschedule session
  -------------------------------------------*/
  /**
   * rescheduleSession
   * @param {function} callback
   */
  rescheduleSession: action(function(callback){
    AppStore.startLoading('rescheduleSession');

    const { installSid } = AppStore.userData;
    const { examSid } = obx.examSession;
    const sessionSid = obx.examSession.sid;

    const data = {
      appointmentDateTime: obx.appointmentDateTime,
      type: obx.examSession.schedulingModule,
      proctorLocationSid: obx.examSession.location.sid
    };

    api.put(`/installs/${installSid}/exams/${examSid}/sessions/${sessionSid}`, data)
      .then(response => {
        obx.examSession = response.data;

        AppStore.finishLoading('rescheduleSession');

        if(callback) {
          callback();
        }
      })
      .catch(error => {
        AppStore.throwError(error);
      });
  }),

  /*-------------------------------------------
    Cancel session
  -------------------------------------------*/
  /**
   * cancelSession
   * @param {object} data
   */
  cancelSession: action(function(data, callback){
    AppStore.startLoading('cancelSession');

    const { installSid } = AppStore.userData;
    const { examSid } = obx.examSession;
    const sessionSid = obx.examSession.sid;

    data.type = obx.examSession.schedulingModule;

    api.delete(`/installs/${installSid}/exams/${examSid}/sessions/${sessionSid}`, { data })
      .then(() => {
        AppStore.finishLoading('cancelSession');

        if(callback) {
          callback();
        }
      })
      .catch(error => {
        AppStore.throwError(error);
      });
  }),

  /*-------------------------------------------
    Look up session
  -------------------------------------------*/
  /**
   * lookUpSession
   * @param {string} email
   * @param {function} callback
   */
  lookUpSession: action(function(email, callback) {
    AppStore.startLoading('lookUpSession');

    const { installSid } = AppStore.userData;

    const data = {
      email
    };

    api.post(`/installs/${installSid}/emailSessions`, data)
      .then(() => {
        AppStore.finishLoading('lookUpSession');

        if(callback) {
          callback();
        }
      })
      .catch(error => {
        AppStore.throwError(error);
      });
  }),

  wait: (ms = 200) => new Promise(resolve => setTimeout(resolve, ms)),

  /*-------------------------------------------
    Get session
  -------------------------------------------*/
  /**
   * getSession
   * @param {string} examSid
   * @param {string} sessionSid
   */
  getSession: action(function(examSid, sessionSid, callback){
    AppStore.startLoading('getSession');

    obx.examSession = null;

    const { installSid } = AppStore.userData;

    api.get(`/installs/${installSid}/exams/${examSid}/sessions/${sessionSid}`)
      .then(response => {
        obx.examSession = response.data;
        obx.proctorLocation = response.data.proctor;

        if (response.data.location.workflowModule) {
          obx.workflowModule = response.data.location.workflowModule;
        }

        if(response.data.schedulingModule !== 'proctoru') {
          obx.proctorLocation.location = response.data.location;
        }

        obx.exam = ExamStore.exams.filter(exam =>
          exam.sid === obx.examSession.examSid
        )[0];

        if(callback) {
          callback(response.data);
        }

        AppStore.finishLoading('getSession');
      })
      .catch(error => {
        AppStore.throwError(error);
      });
  }),

  /*-------------------------------------------
    ProctorU Scheduling and Testing
  -------------------------------------------*/
  /**
   * searchProctorU
   * @param {function} callback
   */
  searchProctorU: action(callback => {
    AppStore.startLoading('searchProctorU');

    const { installSid } = AppStore.userData;

    api.get(`/installs/${installSid}/proctors/search?proctorLocationSids=${config.remoteLocationSid}`)
      .then(response => {
        response.data.results.some(proctorLocation => {
          if(proctorLocation.location.sid === config.remoteLocationSid) {
            // For now, we are hardcoding a price
            proctorLocation.fees.userTotal = 49.95;

            obx.proctorLocation = proctorLocation;
            return true;
          }

          return false;
        });

        if(callback) {
          callback();
        }

        AppStore.finishLoading('searchProctorU');
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  }),

  /**
   * Set the preferred language for an exam session
   * 
   * @function updateSessionLanguage
   * @param {object} urlData
   * @param {string} language
   * @param {Function} callback
   */
  updateSessionLanguage: (urlData, language, callback) => {
    AppStore.startLoading('updateSessionLanguage');

    const { examSid, installSid, sessionSid } = urlData;
    const url = `/installs/${installSid}/exams/${examSid}/sessions/${sessionSid}/preferredLanguage`;

    const payload = {
      preferredLanguage: language
    }

    api.patch(url, payload)
      .then(() => {
        if (callback) {
          callback();
        }

        AppStore.finishLoading('updateSessionLanguage');
      })
      .catch(error => {
        AppStore.throwError(error);
      })
  },

  /**
   * Get a user auth token for a session that we can send
   * to the SP UI for hybrid sessions
   * 
   * @function getUserToken
   * @async
   * @param {object} payload
   */
  getUserToken: async payload => {
    AppStore.startLoading('getUserToken');

    const url = '/authentication/generatetoken';

    const token = await api.post(url, payload)
      .then(response => {
        AppStore.finishLoading('getUserToken');
        return response.data.token;
      })
      .catch(error => {
        AppStore.throwError(error);
      })

    return token;
  },

  /**
   * startOnlineSession
   * @param {string} examSid
   * @param {string} sessionSid
   * @param {function} callback
   * @param {object} window
   */
  startOnlineSession: action((examSid, sessionSid, callback, window) => {
    AppStore.startLoading('startOnlineSession');

    const { installSid } = AppStore.userData;

    api.get(`/installs/${installSid}/exams/${examSid}/sessions/${sessionSid}/start`)
      .then(response => {
        if(callback) {
          callback(response.data.redirect);
        }

        AppStore.finishLoading('startOnlineSession');
      })
      .catch(error => {
        AppStore.throwError(error);

        if(window) {
          window.close();
        }
      })
  })
});

export default obx;
