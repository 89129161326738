/**
 * Renders the view for a proctor to start an exam
 */

import React from 'react';
import { observer } from 'mobx-react';

// Dependencies
import { parseISO } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';

// Translation
import translate from '../../translate/Translate';

// Helpers
import { getUrlSegment } from '../../../helpers/helpers';

const ProvisionExam = observer(class ProvisionExam extends React.Component {


  componentDidMount() {
    const { location } = this.props;
    const { ExamStore, ScheduleStore } = this.props.store;
    const examSid = getUrlSegment(6, location.pathname);
    const sessionSid = getUrlSegment(4, location.pathname);

    // Wait until exams have been retrieved before getting session
    if(examSid && sessionSid) {
      const examDelay = setInterval(function(){
        if(ExamStore.exams.length > 0) {
          clearInterval(examDelay);
          ScheduleStore.getSession(examSid, sessionSid);
        }
      }, 100);
    }
  }

  initConfirm = () => {
    const { translation } = this.props;
    const { AppStore } = this.props.store;
    const msg = translation.confirm_msg;

    AppStore.dialogSubmit = this.handleConfirm;

    AppStore.showDialog(msg, 'prompt');
  }

  handleConfirm = () => {
    const { AppStore, ScheduleStore, ProctorStore } = this.props.store;
    const { examSession } = ScheduleStore;
    const sessionWindow = window.open();

    const data = {
      examSid: examSession.exam.sid,
      sessionSid: examSession.sid,
      status: 'Checked In'
    }

    const callback = (url) => {
      AppStore.closeDialog();
      sessionWindow.location.href = url;
    }

    ProctorStore.provisionOnlineSession(data, callback, sessionWindow);
  }

  render() {
    const { translation } = this.props;
    const { AppStore, ScheduleStore } = this.props.store;
    const { timeZone, loading } = AppStore;
    const { examSession } = ScheduleStore;

    if(!loading) {
      if(examSession) {
        const {
          appointmentDate,
          appointmentEndDate,
          exam,
          status
        } = examSession;

        const formattedStartDate = format(
          utcToZonedTime(parseISO(appointmentDate), timeZone),
          'M/d/yyyy'
        );

        const formattedStartTime = format(
          utcToZonedTime(parseISO(appointmentDate), timeZone),
          'h:mm aaaa zzz'
        );

        const formattedEndDate = format(
          utcToZonedTime(parseISO(appointmentEndDate), timeZone),
          'M/d/yyyy'
        );

        const formattedEndTime = format(
          utcToZonedTime(parseISO(appointmentEndDate), timeZone),
          'h:mm aaaa zzz'
        );

        switch(status) {
          case 'Scheduled':
            return (
              <main className="page wrap">
                <div className="box box--sm box--solo">
                  <h1 className="box__title">
                    {translation.heading}
                  </h1>
    
                  <p>{translation.description}</p>
    
                  <aside className="callout callout--exam-details">
                    <h2 className="callout__title">{translation.details.heading}</h2>
                    <div className="callout__content callout__content--exam-details">
                      <p><strong>{translation.details.exam}</strong> {exam.title}</p>
                      <p><strong>{translation.details.type}</strong> {exam.type}</p>
                      <p><strong>{translation.details.start}</strong> {translation.appointment_date
                            .replace('%time%', formattedStartTime)
                            .replace('%date%', formattedStartDate)}</p>
                      <p><strong>{translation.details.end}</strong> {translation.appointment_date
                            .replace('%time%', formattedEndTime)
                            .replace('%date%', formattedEndDate)}</p>
                    </div>
                  </aside>
    
                  <div className="box__action">
                    <button
                      className="btn btn--outline"
                      onClick={this.initConfirm}
                    >
                      {translation.start}
                    </button>
                  </div>
                </div>
              </main>
            );
          case 'Checked In':
            return (
              <main className="page wrap">
                <div className="box box--sm box--solo">
                  <h1 className="box__title">
                    {translation.heading}
                  </h1>
                  <p className="note">{translation.in_progress}</p>
                </div>
              </main>
            );

          default:
            return (
              <main className="page wrap">
                <div className="box box--sm box--solo">
                  <h1 className="box__title">
                    {translation.heading}
                  </h1>
                  <p className="note">{translation.other_status}</p>
                </div>
              </main>
            );
        }
      } else {
        return null;
      }
    }

    return null;
  }
})

export default translate('ProvisionExam')(ProvisionExam);