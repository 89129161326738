/**
 * Returns the translation for a given component
 */

import React from 'react';
import { observer } from 'mobx-react';

export default function translate(key) {
  return Component => {
    class Translate extends React.Component {
      componentDidMount() {
        // Get the current component’s translation
        const { AppStore } = this.props.store;
        const { getTranslation, translation, translationLoaded } = AppStore;

        // Load the translation if it hasn’t already been retrieved
        if(!translationLoaded) {
          getTranslation();
        }

        // If there is a page title for this component, apply it to the document
        if(translation) {
          let postfix = document.title;
  
          if(postfix.indexOf('| ') > -1) {
            postfix = document.title.split('|')[1];
          }

          let title = postfix;

          if(translation.PageTitles[key]) {
            title = `${translation.PageTitles[key]} | ${postfix}`;
          }

          if(translation.PageTitles[key] || key === 'Home') {
            document.title = title;
          }
        }
      }

      render() {
        const { AppStore } = this.props.store;

        if(AppStore.translation) {
          // Return the translation for the component
          let translation = AppStore.translation[key];

          return (
            <Component
              {...this.props}
              translation={translation}
            />
          )
        } else {
          return (
            <span className="no-results">Translation not found</span>
          );
        }
      }
    }

    return observer(Translate);
  }
}