export let routes = {
	index: '/',
	home: '/:domainId',
	schedule: '/:domainId/schedule/:examId',
	reschedule: '/:domainId/reschedule/:examId',
	modifyAppointment: {
		lookup: '/:domainId/modify-appointment',
		emailSent: '/:domainId/modify-appointment/email-sent'
	}, 
	appointmentDetails: '/:domainId/appointment-details',
	appointmentSetup: '/:domainId/appointment-setup',
	provisionExam: {
		provision: '/:domainId/exam-entry/session/:sessionSid/exam/:examSid'
	}
};