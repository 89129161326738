/**
 * Renders a proctor location list item
 */

import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

// Helpers
import { getMapUrl } from '../../helpers/helpers';

// Translation
import translate from '../translate/Translate';

// Dependencies
import currencyFormatter from 'currency-formatter';
import { format, parseISO } from 'date-fns';

// Components
import Tooltip from './Tooltip';
import Address from './Address';
import * as icons from '../ui/Icons';

const Location = observer(class Location extends React.Component {
	constructor() {
		super();

		// Set the initial state
		this.state = {
			expandFeatures: false,
			features: []
		};
	}

	componentDidMount() {
		const { translation } = this.props;
		const { compact, location } = this.props.location;

		// Format the features as an array to make them easier to parse
		if(!compact) {
			const features = [];

			if(location.hasComputerAvailable) {
				features.push({
					title: translation.feature_options.hasComputerAvailable,
					icon: <icons.computer />
				});
			}

			if(location.hasPrinterAvailable) {
				features.push({
					title: translation.feature_options.hasPrinterAvailable,
					icon: <icons.print />
				});
			}

			if(location.hasWheelchairRamp) {
				features.push({
					title: translation.feature_options.hasWheelchairRamp,
					icon: <icons.wheelchair />
				});
			}

			if(location.hasElevator) {
				features.push({
					title: translation.feature_options.hasElevator,
					icon: <icons.elevator />
				});
			}

			if(location.hasDistractionFreeRooms) {
				features.push({
					title: translation.feature_options.hasDistractionFreeRooms,
					icon: <icons.distraction />
				});
			}

			if(location.hasScreenReader) {
				features.push({
					title: translation.feature_options.hasScreenReader,
					icon: <icons.speaker />
				});
			}

			if(location.hasSpeechRecognition) {
				features.push({
					title: translation.feature_options.hasSpeechRecognition,
					icon: <icons.speech />
				});
			}

			if(location.hasTextToSpeech) {
				features.push({
					title: translation.feature_options.hasTextToSpeech,
					icon: <icons.tts />
				});
			}

			if(location.hasScreenEnlarger) {
				features.push({
					title: translation.feature_options.hasScreenEnlarger,
					icon: <icons.enlarge />
				});
			}

			if(location.hasAlternativeKeyboards) {
				features.push({
					title: translation.feature_options.hasAlternativeKeyboards,
					icon: <icons.keyboard />
				});
			}

			if(location.hasCopierAvailable) {
				features.push({
					title: translation.feature_options.hasCopierAvailable,
					icon: <icons.copy />
				});
			}

			if(location.hasFaxAvailable) {
				features.push({
					title: translation.feature_options.hasFaxAvailable,
					icon: <icons.fax />
				});
			}

			if(location.smokeFree) {
				features.push({
					title: translation.feature_options.smokeFree,
					icon: <icons.smokeFree />
				});
			}

			this.setState({
				features
			});
		}
	}

  render() {
		const { compact, location, onSelect, translation } = this.props;
		const {
			address1,
			distance,
			locationLatitude,
			locationLongitude,
			parkingInformation,
			place,
			postalCode,
			region
		} = location.location;
		const { currency } = this.props.store.ScheduleStore;

    return (
      <article className={`location ${compact ? 'location--compact' : ''}`}>
				<div className="location__adr media">
					<section className="media__caption">
						<h2
							id={`location-${location.location.sid}`}
							className="location__title"
						>
							{location.proctor.accountName || location.location.accountName}
						</h2>

						<Address
							location={location.location}
						/>

						{distance &&
							<span className="location__distance">
								{distance} {distance === 1 ? translation.mile : translation.miles}
							</span>
						}

						{parkingInformation &&
							<span className="location__parking">
								<span aria-describedby={`parking-info-${location.location.sid}`}>
									{translation.parking_information}
								</span>

								<Tooltip
									{...this.props}
									id={`parking-info-${location.location.sid}`}
									content={parkingInformation}
								/>
							</span>
						}
					</section>

					<a
						className="location__map media__content"
						href={`https://maps.google.com/?q=${address1} ${place}, ${region} ${postalCode}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							src={getMapUrl(locationLatitude, locationLongitude)}
							width="150"
							height="150"
							alt={`A map of ${location.proctor.accountName}’s location`}
						/>
					</a>
				</div>

				{!compact &&
					<div className="location__action">
						<strong className="location__price">
							{currencyFormatter.format(
								location.fees.userTotal,
								{code: currency}
							)} {currency}
						</strong>

						{location.location.nextAvailability &&
							<div className="location__next-slot">
								<strong>{translation.next_appointment}</strong>
								<span>{format(parseISO(location.location.nextAvailability), 'M/d h:mm a')}</span>
							</div>
						}

						<Link
							className="btn"
							to="time"
							onClick={() => onSelect(location)}
							aria-labelledby={`location-${location.location.sid}`}
						>
							{translation.select}
						</Link>
					</div>
				}

				{!compact &&
					<div className="location__detail">
						{this.state.features.length > 0 &&
							<section className="location__section">
								<h3 className="meta">{translation.features}</h3>

								<ul className="location__features">
									{this.state.features.map(
										(feature, i) =>
										<li key={i}>
											{feature.icon}
											{feature.title}
										</li>
									)}
								</ul>
							</section>
						}
					</div>
				}
			</article>
    )
  }
})

export default translate('Location')(Location);