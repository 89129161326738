import React from 'react';

export function calendar() {
  return (
    <svg aria-hidden="true" className="icon icon--calendar" xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" aria-labelledby="icon-calendar-title">
      <title id="icon-calendar-title">Calendar</title>
      <path d="M4 10H6V12H4V10ZM18 4V18C18 18.5304 17.7893 19.0391 17.4142 19.4142C17.0391 19.7893 16.5304 20 16 20H2C0.89 20 0 19.1 0 18V4C0 3.46957 0.210714 2.96086 0.585786 2.58579C0.960859 2.21071 1.46957 2 2 2H3V0H5V2H13V0H15V2H16C16.5304 2 17.0391 2.21071 17.4142 2.58579C17.7893 2.96086 18 3.46957 18 4ZM2 6H16V4H2V6ZM16 18V8H2V18H16ZM12 12V10H14V12H12ZM8 12V10H10V12H8ZM4 14H6V16H4V14ZM12 16V14H14V16H12ZM8 16V14H10V16H8Z"/>
    </svg>
  )
}

export function chevronLeft() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" className="icon icon--chevron-left" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"><path fillRule="evenodd" clipRule="evenodd" d="M9.63604 1.63604L10.9997 2.99975L5.99949 8L10.9997 13.0003L9.63604 14.364L3.27208 8L4.63578 6.63629L9.63604 1.63604Z" fill="currentColor"/></svg>
  )
}

export function chevronLeftDouble() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" className="icon icon--chevron-left-double" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"><path fillRule="evenodd" clipRule="evenodd" d="M8.99975 2.99975L7.63604 1.63604L2.63578 6.63629L1.27208 8L7.63604 14.364L8.99974 13.0003L3.99949 8L8.99975 2.99975ZM14.9997 2.99975L13.636 1.63604L8.63578 6.63629L7.27208 8L13.636 14.364L14.9997 13.0003L9.99949 8L14.9997 2.99975Z" fill="currentColor"/></svg>
  )
}

export function chevronRight() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" className="icon icon--chevron-right" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"><path fillRule="evenodd" clipRule="evenodd" d="M6.36396 1.63604L5.00025 2.99975L10.0005 8L5.00026 13.0003L6.36396 14.364L12.7279 8L11.3642 6.63629L6.36396 1.63604Z" fill="currentColor"/></svg>
  )
}

export function chevronRightDouble() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" className="icon icon--chevron-right-double" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"><path fillRule="evenodd" clipRule="evenodd" d="M1.00025 2.99975L2.36396 1.63604L7.36422 6.63629L8.72792 8L2.36396 14.364L1.00026 13.0003L6.00051 8L1.00025 2.99975ZM7.00025 2.99975L8.36396 1.63604L13.3642 6.63629L14.7279 8L8.36396 14.364L7.00026 13.0003L12.0005 8L7.00025 2.99975Z" fill="currentColor"/></svg>
  )
}

export function computer() {
  return (
    <svg aria-hidden="true" className="icn icn--computer" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" aria-labelledby="icn-computer-title">
      <title id="icn-computer-title">Computer</title>
      <path d="M3.2 0C2.312 0 1.6 0.712 1.6 1.6V8C1.6 8.888 2.312 9.6 3.2 9.6H12.8C13.688 9.6 14.4 8.888 14.4 8V1.6C14.4 0.712 13.688 0 12.8 0H3.2ZM3.2 1.6H12.8V8H3.2V1.6ZM1.6 10.4C0.712 10.4 0 11.112 0 12V14.4C0 15.288 0.712 16 1.6 16H14.4C15.288 16 16 15.288 16 14.4V12C16 11.112 15.288 10.4 14.4 10.4H1.6ZM4.8 12H14.4V14.4H4.8V12ZM5.6 12.6V13.8H8.8V12.6H5.6ZM10.4 12.6V13.8H13.6V12.6H10.4Z"/>
    </svg>
  )
}

export function copy() {
  return (
    <svg aria-hidden="true" className="icn icn--copy" xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" aria-labelledby="icn-copy-title">
      <title id="icn-copy-title">Copy</title>
      <path d="M12.3636 14.5455H4.36364V4.36364H12.3636V14.5455ZM12.3636 2.90909H4.36364C3.97787 2.90909 3.6079 3.06234 3.33512 3.33512C3.06234 3.6079 2.90909 3.97787 2.90909 4.36364V14.5455C2.90909 14.9312 3.06234 15.3012 3.33512 15.574C3.6079 15.8468 3.97787 16 4.36364 16H12.3636C12.7494 16 13.1194 15.8468 13.3922 15.574C13.6649 15.3012 13.8182 14.9312 13.8182 14.5455V4.36364C13.8182 3.97787 13.6649 3.6079 13.3922 3.33512C13.1194 3.06234 12.7494 2.90909 12.3636 2.90909V2.90909ZM10.1818 0H1.45455C1.06878 0 0.698807 0.153246 0.426026 0.426027C0.153246 0.698807 0 1.06878 0 1.45455V11.6364H1.45455V1.45455H10.1818V0Z"/>
    </svg>
  )
}

export function distraction() {
  return (
    <svg aria-hidden="true" className="icn icn--distraction" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" aria-labelledby="icn-distraction-title">
      <title id="icn-distraction-title">Distraction</title>
      <path d="M0 2.34841L0.985563 1.37055L14.6295 15.0144L13.6516 16L9.17036 11.5188C8.61598 11.95 8.21559 12.2964 7.9846 12.9971C7.52262 14.3985 6.92974 15.1838 5.88258 15.7305C5.48989 15.9076 5.05871 16 4.61983 16C3.80299 16 3.01961 15.6755 2.44202 15.0979C1.86443 14.5203 1.53994 13.737 1.53994 12.9201H3.07988C3.07988 13.3285 3.24213 13.7202 3.53092 14.009C3.81972 14.2978 4.21141 14.4601 4.61983 14.4601C4.84312 14.4601 5.05101 14.4139 5.205 14.3446C5.75168 14.0751 6.13667 13.667 6.52166 12.512C6.86044 11.4726 7.46102 10.9105 8.0847 10.4254L3.11068 5.4591C3.07988 5.63619 3.07988 5.81328 3.07988 5.99037H1.53994C1.53994 5.3513 1.64774 4.75072 1.84023 4.18864L0 2.34841ZM10.1482 8.25409C10.5563 7.53032 10.7796 6.70645 10.7796 5.99037C10.7796 3.83446 9.08566 2.14052 6.92974 2.14052C6.01347 2.14052 5.1896 2.44081 4.53513 2.95669L3.43407 1.85563C4.36574 1.07026 5.58229 0.600577 6.92974 0.600577C9.95573 0.600577 12.3195 2.96439 12.3195 5.99037C12.3195 6.96054 12.0269 8.0308 11.4957 8.99326L11.1415 9.57074L10.0327 8.45428L10.1482 8.25409ZM11.8268 1.09336L12.9201 0C14.4524 1.53224 15.3994 3.64966 15.3994 5.99037C15.3994 8.16939 14.5756 10.1559 13.2281 11.6574L12.1424 10.564C13.205 9.34745 13.8595 7.74591 13.8595 5.99037C13.8595 4.06545 13.0895 2.34071 11.8268 1.09336ZM8.85467 5.99037C8.85467 6.37536 8.74687 6.70645 8.56978 6.99904L5.92108 4.35034C6.21367 4.17324 6.54475 4.06545 6.92974 4.06545C7.44026 4.06545 7.92988 4.26825 8.29087 4.62925C8.65186 4.99024 8.85467 5.47985 8.85467 5.99037Z"/>
    </svg>
  )
}

export function elevator() {
  return (
    <svg aria-hidden="true" className="icn icn--elevator" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" aria-labelledby="icn-elevator-title">
      <title id="icn-elevator-title">Elevator</title>
      <path d="M3.55556 0L6.71605 3.2H4.34568V6.4H2.76543V3.2H0.395062L3.55556 0ZM11.4568 6.4L8.2963 3.2H10.6667V0H12.2469V3.2H14.6173L11.4568 6.4ZM1.58025 8H14.4198C14.8389 8 15.2408 8.16857 15.5372 8.46863C15.8335 8.76869 16 9.17565 16 9.6V14.4C16 14.8243 15.8335 15.2313 15.5372 15.5314C15.2408 15.8314 14.8389 16 14.4198 16H1.58025C1.16114 16 0.759197 15.8314 0.462844 15.5314C0.16649 15.2313 0 14.8243 0 14.4V9.6C0 9.17565 0.16649 8.76869 0.462844 8.46863C0.759197 8.16857 1.16114 8 1.58025 8ZM1.58025 9.6V14.4H14.4198V9.6H1.58025Z"/>
    </svg>
  )
}

export function enlarge() {
  return (
    <svg aria-hidden="true" className="icn icn--enlarge" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" aria-labelledby="icn-enlarge-title">
      <title id="icn-enlarge-title">Enlarge</title>
      <path d="M5.77778 8.96889L7.03111 10.2222L3.03111 14.2222H6.22222V16H0V9.77778H1.77778V12.9689L5.77778 8.96889ZM7.03111 5.77778L5.77778 7.03111L1.77778 3.03111V6.22222H0V0H6.22222V1.77778H3.03111L7.03111 5.77778ZM10.2222 8.96889L14.2222 12.9689V9.77778H16V16H9.77778V14.2222H12.9689L8.96889 10.2222L10.2222 8.96889ZM8.96889 5.77778L12.9689 1.77778H9.77778V0H16V6.22222H14.2222V3.03111L10.2222 7.03111L8.96889 5.77778Z"/>
    </svg>
  )
}

export function fax() {
  return (
    <svg aria-hidden="true" className="icn icn--fax" xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" aria-labelledby="icn-fax-title">
      <title id="icn-fax-title">Fax</title>
      <path d="M6.4 2.66667H9.95555V4.44444H6.4V2.66667ZM4.26667 5.33333V0H12.0889V5.33333C12.6547 5.33333 13.1973 5.61428 13.5974 6.11438C13.9975 6.61448 14.2222 7.29276 14.2222 8V13.3333H12.0889V16H4.26667V13.3333H3.55556V5.33333H4.26667ZM5.68889 1.77778V5.33333H10.6667V1.77778H5.68889ZM5.68889 10.6667V14.2222H10.6667V10.6667H5.68889ZM12.0889 7.11111C11.9003 7.11111 11.7194 7.20476 11.5861 7.37146C11.4527 7.53816 11.3778 7.76425 11.3778 8C11.3778 8.23575 11.4527 8.46184 11.5861 8.62854C11.7194 8.79524 11.9003 8.88889 12.0889 8.88889C12.2775 8.88889 12.4584 8.79524 12.5917 8.62854C12.7251 8.46184 12.8 8.23575 12.8 8C12.8 7.76425 12.7251 7.53816 12.5917 7.37146C12.4584 7.20476 12.2775 7.11111 12.0889 7.11111ZM1.42222 5.33333H2.13333C2.32193 5.33333 2.50281 5.42698 2.63616 5.59368C2.76952 5.76038 2.84444 5.98647 2.84444 6.22222V12.4444C2.84444 12.6802 2.76952 12.9063 2.63616 13.073C2.50281 13.2397 2.32193 13.3333 2.13333 13.3333H1.42222C1.04503 13.3333 0.683278 13.146 0.416559 12.8126C0.149841 12.4792 0 12.0271 0 11.5556V7.11111C0 6.63962 0.149841 6.18743 0.416559 5.85403C0.683278 5.52063 1.04503 5.33333 1.42222 5.33333V5.33333Z"/>
    </svg>
  )
}

export function keyboard() {
  return (
    <svg aria-hidden="true" className="icn icn--keyboard" xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" aria-labelledby="icn-keyboard-title">
      <title id="icn-keyboard-title">Keyboard</title>
      <path d="M13.6 4H12V2.4H13.6V4ZM13.6 6.4H12V4.8H13.6V6.4ZM11.2 4H9.6V2.4H11.2V4ZM11.2 6.4H9.6V4.8H11.2V6.4ZM11.2 9.6H4.8V8H11.2V9.6ZM4 4H2.4V2.4H4V4ZM4 6.4H2.4V4.8H4V6.4ZM4.8 4.8H6.4V6.4H4.8V4.8ZM4.8 2.4H6.4V4H4.8V2.4ZM7.2 4.8H8.8V6.4H7.2V4.8ZM7.2 2.4H8.8V4H7.2V2.4ZM14.4 0H1.6C0.712 0 0 0.712 0 1.6V9.6C0 10.0243 0.168571 10.4313 0.468629 10.7314C0.768687 11.0314 1.17565 11.2 1.6 11.2H14.4C14.8243 11.2 15.2313 11.0314 15.5314 10.7314C15.8314 10.4313 16 10.0243 16 9.6V1.6C16 0.712 15.28 0 14.4 0Z"/>
    </svg>
  )
}

export function print() {
  return (
    <svg aria-hidden="true" className="icn icn--print" xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" aria-labelledby="icn-print-title">
      <title id="icn-print-title">Fax</title>
      <path d="M12.8 0H3.2V3.2H12.8V0ZM13.6 7.2C13.3878 7.2 13.1843 7.11572 13.0343 6.96569C12.8843 6.81566 12.8 6.61217 12.8 6.4C12.8 6.18783 12.8843 5.98434 13.0343 5.83432C13.1843 5.68429 13.3878 5.6 13.6 5.6C13.8122 5.6 14.0157 5.68429 14.1657 5.83432C14.3157 5.98434 14.4 6.18783 14.4 6.4C14.4 6.61217 14.3157 6.81566 14.1657 6.96569C14.0157 7.11572 13.8122 7.2 13.6 7.2ZM11.2 12.8H4.8V8.8H11.2V12.8ZM13.6 4H2.4C1.76348 4 1.15303 4.25286 0.702944 4.70294C0.252856 5.15303 0 5.76348 0 6.4V11.2H3.2V14.4H12.8V11.2H16V6.4C16 5.76348 15.7471 5.15303 15.2971 4.70294C14.847 4.25286 14.2365 4 13.6 4Z"/>
    </svg>
  )
}

export function smokeFree() {
  return (
    <svg aria-hidden="true" className="icn icn--smokeFree" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" aria-labelledby="icn-smokeFree-title">
      <title id="icn-smokeFree-title">Smoke Free</title>
      <path d="M0 3.04762L5.33333 8.38095H0V10.6667H7.61905L12.9524 16L13.9048 15.0476L0.952381 2.09524L0 3.04762ZM14.0952 8.38095H15.2381V10.6667H14.0952V8.38095ZM12.1905 8.38095H13.3333V10.6667H12.1905V8.38095ZM12.8381 2.19429C13.3105 1.72952 13.6 1.08952 13.6 0.380952H12.4571C12.4571 1.14286 11.8095 1.79048 11.0476 1.79048V2.93333C12.7543 2.93333 14.0952 4.32762 14.0952 6.03429V7.61905H15.2381V6.03429C15.2381 4.33524 14.2629 2.87238 12.8381 2.19429ZM9.52381 5.10476H10.6895C11.4895 5.10476 12.1905 5.66857 12.1905 6.66667V7.61905H13.3333V6.40762C13.3333 5.03619 12.1143 4 10.6895 4H9.52381C8.7619 4 8.11428 3.25333 8.11428 2.47619C8.11428 1.69905 8.7619 1.14286 9.52381 1.14286V0C8.84688 0 8.19767 0.268911 7.719 0.747575C7.24034 1.22624 6.97143 1.87545 6.97143 2.55238C6.97143 3.22931 7.24034 3.87852 7.719 4.35719C8.19767 4.83585 8.84688 5.10476 9.52381 5.10476V5.10476ZM11.4286 10.6133V8.38095H9.19619L11.4286 10.6133Z"/>
    </svg>
  )
}

export function speaker() {
  return (
    <svg aria-hidden="true" className="icn icn--speaker" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" aria-labelledby="icn-speaker-title">
      <title id="icn-speaker-title">Speaker</title>
      <path d="M9.77778 0V1.83111C12.3467 2.59556 14.2222 4.97778 14.2222 7.79556C14.2222 10.6133 12.3467 12.9867 9.77778 13.7511V15.5911C13.3333 14.7822 16 11.6 16 7.79556C16 3.99111 13.3333 0.808889 9.77778 0ZM12 7.79556C12 6.22222 11.1111 4.87111 9.77778 4.21333V11.3511C11.1111 10.72 12 9.36 12 7.79556ZM0 5.12889V10.4622H3.55556L8 14.9067V0.684444L3.55556 5.12889H0Z"/>
    </svg>
  )
}

export function speech() {
  return (
    <svg aria-hidden="true" className="icn icn--speech" xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" aria-labelledby="icn-speech-title">
      <title id="icn-speech-title">Speech</title>
      <path d="M5.81653 2.1812C6.58785 2.1812 7.32757 2.4876 7.87298 3.03301C8.41839 3.57841 8.72479 4.31814 8.72479 5.08946C8.72479 5.86078 8.41839 6.60051 7.87298 7.14591C7.32757 7.69132 6.58785 7.99772 5.81653 7.99772C5.04521 7.99772 4.30548 7.69132 3.76007 7.14591C3.21467 6.60051 2.90826 5.86078 2.90826 5.08946C2.90826 4.31814 3.21467 3.57841 3.76007 3.03301C4.30548 2.4876 5.04521 2.1812 5.81653 2.1812V2.1812ZM5.81653 9.45186C7.75779 9.45186 11.6331 10.4261 11.6331 12.3601V13.8143H0V12.3601C0 10.4261 3.87526 9.45186 5.81653 9.45186ZM11.4586 2.44294C12.9272 4.04249 12.9272 6.26004 11.4586 7.72871L10.2371 6.49997C10.8478 5.64203 10.8478 4.52962 10.2371 3.67168L11.4586 2.44294ZM13.8651 0C16.7225 2.94462 16.7007 7.35064 13.8651 10.1789L12.68 8.9938C14.694 6.68174 14.694 3.38086 12.68 1.18512L13.8651 0Z"/>
    </svg>
  )
}

export function times() {
  return (
    <svg aria-hidden="true" className="icon icon--times" xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" aria-labelledby="icon-times-title">
      <title id="icon-times-title">Times</title>
      <path d="M0.956055 9.75875L4.62272 6.02541L0.978277 2.30319L2.3005 0.936523L5.95605 4.65875L9.61161 0.936523L10.9338 2.30319L7.28939 6.02541L10.9561 9.75875L9.63383 11.1254L5.95605 7.38097L2.27828 11.1254L0.956055 9.75875Z"/>
    </svg>
  )
}

export function tts() {
  return (
    <svg aria-hidden="true" className="icn icn--tts" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" aria-labelledby="icn-tts-title">
      <title id="icn-tts-title">Text-to-speech</title>
			<path d="M4.8 4C5.22435 4 5.63131 4.16857 5.93137 4.46863C6.23143 4.76869 6.4 5.17565 6.4 5.6V9.6C6.4 10.0243 6.23143 10.4313 5.93137 10.7314C5.63131 11.0314 5.22435 11.2 4.8 11.2C4.37565 11.2 3.96869 11.0314 3.66863 10.7314C3.36857 10.4313 3.2 10.0243 3.2 9.6V5.6C3.2 5.17565 3.36857 4.76869 3.66863 4.46863C3.96869 4.16857 4.37565 4 4.8 4V4ZM9.6 9.6C9.6 11.976 7.872 13.952 5.6 14.336V16H4V14.336C1.728 13.952 0 11.976 0 9.6H1.6C1.6 10.4487 1.93714 11.2626 2.53726 11.8627C3.13737 12.4629 3.95131 12.8 4.8 12.8C5.64869 12.8 6.46263 12.4629 7.06274 11.8627C7.66286 11.2626 8 10.4487 8 9.6H9.6ZM15.528 5.928L12.136 9.328L12.944 6.4H9.6C9.17565 6.4 8.76869 6.23143 8.46863 5.93137C8.16857 5.63131 8 5.22435 8 4.8V1.6C8 1.17565 8.16857 0.768687 8.46863 0.468629C8.76869 0.168571 9.17565 0 9.6 0H14.4C14.8243 0 15.2313 0.168571 15.5314 0.468629C15.8314 0.768687 16 1.17565 16 1.6V4.8C16 5.24 15.824 5.64 15.528 5.928Z"/>
    </svg>
  )
}

export function wheelchair() {
  return (
    <svg aria-hidden="true" className="icn icn--wheelchair" xmlns="http://www.w3.org/2000/svg" width="13" height="16" viewBox="0 0 13 16" aria-labelledby="icn-wheelchair-title">
      <title id="icn-wheelchair-title">Wheelchair</title>
			<path d="M10.9714 7.39048L7.84762 7.54286L9.6 5.56191C9.75238 5.33333 9.82857 4.95238 9.75238 4.57143C9.67619 4.34286 9.6 4.11429 9.37143 3.9619L5.25714 1.52381C4.95238 1.29524 4.49524 1.37143 4.19048 1.6L2.13333 3.50476C1.75238 3.88571 1.67619 4.41905 2.05714 4.8C2.3619 5.18095 2.97143 5.18095 3.35238 4.87619L4.87619 3.50476L6.32381 4.34286L3.12381 7.61905C3.04762 7.69524 3.04762 7.77143 2.97143 7.77143C2.59048 7.92381 2.20952 8.07619 1.90476 8.30476L3.04762 9.44762C3.42857 9.29524 3.80952 9.14286 4.19048 9.14286C5.6381 9.14286 6.85714 10.3619 6.85714 11.8095C6.85714 12.2667 6.78095 12.6476 6.55238 12.9524L7.69524 14.0952C8.15238 13.4095 8.38095 12.6476 8.38095 11.8095C8.38095 10.8952 8.07619 9.98095 7.54286 9.29524L10.0571 9.06667L9.90476 12.7238C9.82857 13.2571 10.2095 13.6381 10.7429 13.7143H10.819C11.2762 13.7143 11.6571 13.3333 11.7333 12.8762L11.8857 8.38095C11.8857 8.15238 11.8095 7.84762 11.6571 7.69524C11.4286 7.46667 11.2 7.39048 10.9714 7.39048ZM10.6667 3.04762C11.0708 3.04762 11.4584 2.88708 11.7442 2.60131C12.0299 2.31554 12.1905 1.92795 12.1905 1.52381C12.1905 1.11967 12.0299 0.732083 11.7442 0.446313C11.4584 0.160544 11.0708 0 10.6667 0C10.2625 0 9.87494 0.160544 9.58917 0.446313C9.3034 0.732083 9.14286 1.11967 9.14286 1.52381C9.14286 1.92795 9.3034 2.31554 9.58917 2.60131C9.87494 2.88708 10.2625 3.04762 10.6667 3.04762ZM6.47619 15.3143C5.79048 15.7714 5.02857 16 4.19048 16C1.90476 16 0 14.0952 0 11.8095C0 10.9714 0.228571 10.2095 0.685714 9.52381L1.82857 10.6667C1.67619 11.0476 1.52381 11.4286 1.52381 11.8095C1.52381 13.2571 2.74286 14.4762 4.19048 14.4762C4.64762 14.4762 5.02857 14.4 5.33333 14.1714L6.47619 15.3143Z"/>
    </svg>
  )
}
