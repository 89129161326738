/**
 * Renders the user agreements
 */

import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

// Translation
import translate from '../../translate/Translate';

const Agreements = observer(class Agreements extends React.Component {
	componentDidMount() {
		const { history } = this.props;
		const { ScheduleStore, AppStore } = this.props.store;
		const { externalAppointmentId } = ScheduleStore;
		const { userData } = AppStore;




		if(ScheduleStore.schedulingModule === "in-person") {
			// Get any agreements
			if (window.Agreements) {
				window.Agreements.setup({
					mode: 'inline',
					containerId: 'agreement-container',
					location: 'dmv-hybrid-check-in',
					target: externalAppointmentId,
					account: userData.deploymentSid,
					application: userData.applicationSid
				});
			}
		}
		else {
			if (window.Agreements) {
				window.Agreements.setup({
					mode: 'inline',
					containerId: 'agreement-container',
					location: 'dmv-appointment-registration',
					target: externalAppointmentId,
					account: userData.deploymentSid,
					application: userData.applicationSid
				});
			}
		}



			document.addEventListener('agreementsComplete', function(){
				history.push('payment');
			});
		}


	componentWillUnmount() {
		if(window.Agreements) {
			window.Agreements.agrFetched = false;
		}
	}

  render() {
		const { translation } = this.props;

    return (
      <div className="box box--md box--solo">
				<Link
          className="back box__back"
          to="user-information"
        >
          {translation.back}
        </Link>

				<div className="loader--async" id="agreement-container"></div>
			</div>
    )
  }
})

export default translate('Agreements')(Agreements);