/**
 * Renders a list of proctor locations that a user can schedule at
 */

import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

// Translation
import translate from '../../translate/Translate';

// Components
import Location from '../../common/Location';

const SelectLocation = observer(class SelectLocation extends React.Component {
  constructor() {
    super();

    // Bind this to function
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(location) {
    const { ScheduleStore } = this.props.store;

    ScheduleStore.proctorLocation = location;
  }

  render() {
    const { store, translation } = this.props;
    const { AppStore, ProctorStore } = store;
    const { locations } = ProctorStore;

    return (
      <div className="box box--md box--solo">
        <Link
          className="back box__back"
          to="geolocate"
        >
          {translation.back}
        </Link>

        <h1 className="box__title">
          {translation.heading}
        </h1>

        {locations.length > 0 && !AppStore.loading &&
          <ol className="result-list">
            {locations.map(
              (location, i) =>
              <li key={i}>
                <Location
                  {...this.props}
                  location={location}
                  onSelect={this.handleSelect}
                />
              </li>
            )}
          </ol>
        }

        {locations.length === 0 &&
          <span className="no-results">
            {translation.no_results}
          </span>
        }
      </div>
    )
  }
})

export default translate('SelectLocation')(SelectLocation);