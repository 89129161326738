/**
 * Renders the homepage view
 */

import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

// Translation
import translate from '../translate/Translate';

const DomainSelect = observer(class DomainSelect extends React.Component {
  componentDidMount() {
    const { AppStore, DomainStore } = this.props.store;

    // Reset domain data
    AppStore.token = null;
    DomainStore.resetDomain();

    // Set focus to the main page element
    if(this.page) {
      this.page.focus();
    }
  }

  render() {
    const { translation } = this.props;
    const { domains } = this.props.store.DomainStore;

    return (
      <main
        className="page page--solo wrap"
        ref={(page) => this.page = page}
        tabIndex="-1"
        aria-labelledby="page-title"
      >
        <div className="box box--md box--solo">
          <h1 id="page-title" className="box__title">
            {translation.heading}
          </h1>

          <ul className="result-list">
            {domains.map(
                (domain) =>
                <li key={domain.id}>
                  <article className="media media--alt">
                    <div className="media__caption">
                      <h2 className="meta">{domain.title}</h2>

                      <img
                        className="brand__logo"
                        src={`/static/img/logos/${domain.id}.png`}
                        height="50"
                        alt={`The ${domain.title} logo`}
                        role="presentation"
                      />
                    </div>

                    <div className="media__content">
                      <Link
                        className="btn"
                        to={domain.id}
                      >
                        {translation.select.replace('%domain%', domain.title)}
                      </Link>
                    </div>
                  </article>
                </li>
              )}
          </ul>
        </div>
      </main>
    )
  }
})

export default translate('DomainSelect')(DomainSelect);