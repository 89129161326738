/**
 * Renders the scheduling complete message
 */

 import React from 'react';
 import { observer } from 'mobx-react';
 
 import { config } from '../../../config';
 
 // Translation
 import translate from '../../translate/Translate';
 
 // Dependencies
 import { parseISO } from 'date-fns';
 import { format, utcToZonedTime } from 'date-fns-tz';
 
 const Complete = observer(class Complete extends React.Component {
   componentDidMount() {
     const _this = this;
 
     // Reset scheduling data (but wait for previous step to unmount)
     setTimeout(function(){
       _this.props.store.ScheduleStore.resetScheduleData();
     }, 500);
   }
 
   render() {
     const { translation } = this.props;
     const { ScheduleStore, AppStore } = this.props.store;
     const { examSession } = ScheduleStore;
     const { timeZone } = AppStore;
     const isOnlineExam = examSession && examSession.location && examSession.location.workflowModule !== "in-person";
     const examSid = ScheduleStore.examSession.examSid;
     const sessionId = ScheduleStore.examSession.sid;
     const examTitle = ScheduleStore.exam.title;
 
     const {
       address1,
       address2,
       place,
       postalCode,
       region
     } = examSession.location;
     let formattedLocation = '';
 
     const formattedDate = format(
       utcToZonedTime(parseISO(examSession.appointmentDate), timeZone),
       'M/d/yyyy'
     );
 
     const formattedTime = format(
       utcToZonedTime(parseISO(examSession.appointmentDate), timeZone),
       'h:mm aaaa zzz'
     );
 
     if(address2) {
       formattedLocation = `${address1} ${address2}, ${place}, ${region} ${postalCode}`;
     } else {
       formattedLocation = `${address1}, ${place}, ${region} ${postalCode}`;
     }
 
     return (
       <div className="box box--md box--solo">
         <h1 className="box__title">
           {translation.heading}
         </h1>
 
         {!isOnlineExam &&
          <React.Fragment>
             <p>
             {translation.body
                         .replace('%time%', formattedTime)
                         .replace('%date%', formattedDate)
                         .replace('%location%', formattedLocation)
                         .replace('%examTitle%', examTitle)}
           </p>
           <p>{translation.body_cont}</p>
           <p><b>{translation.exam_loc}</b></p>
           <p>{translation.exam_loc_info}</p>
           <p><b>{translation.day_of_exam}</b></p>
           <p>{translation.documentation}</p>
           <p dangerouslySetInnerHTML={{__html: translation.proof_identity}}/>
           <p dangerouslySetInnerHTML={{__html: translation.identity_docs}}/>
           <p dangerouslySetInnerHTML={{__html: translation.proof_social}}/>
           <p dangerouslySetInnerHTML={{__html: translation.social_docs}}/>
           <p dangerouslySetInnerHTML={{__html: translation.proof_residency}}/>
           <p dangerouslySetInnerHTML={{__html: translation.residency_docs}}/>
           <p>{translation.button_info}</p>

           <a className="btn btn-primary" href={`${config.domain}/dc/appointment-details?examSid=${examSid}&sessionSid=${sessionId}`} rel="noopener noreferrer" target="_blank">
               {translation.button_text}
           </a>
          </React.Fragment>

         }
 
         {isOnlineExam &&
         <React.Fragment>
           <p>
             {translation.body_remote
                         .replace('%time%', formattedTime)
                         .replace('%date%', formattedDate)}
           </p>
           <p>{translation.body_remote_cont}</p>
           <p dangerouslySetInnerHTML={{ __html: translation.body_remote_cont_2}}/>
           <p>{translation.body_remote_cont_3}</p>
           <p>{translation.button_info}</p>
 
           <a className="btn btn-primary" href={`${config.domain}/dc/appointment-details?examSid=${examSid}&sessionSid=${sessionId}`} rel="noopener noreferrer" target="_blank">
               {translation.button_text}
           </a>
         </React.Fragment>
 
         }
       </div>
     )
   }
 })
 
 export default translate('Complete')(Complete);