/**
 * Renders a view that allows users to look up their appointment details
 */

import React from 'react';
import { observer } from 'mobx-react';

// Helpers
import { checkValidity } from '../../../helpers/helpers';

// Translation
import translate from '../../translate/Translate';

const AppointmentLookup = observer(class AppointmentLookup extends React.Component {
  constructor() {
    super();

    // Bind this to functions
    this.handleSubmit = this.handleSubmit.bind(this);
    this.complete = this.complete.bind(this);
  }

  handleSubmit(e) {
    const { ScheduleStore } = this.props.store;

    ScheduleStore.lookUpSession(this.email.value, this.complete);
  }

  complete() {
    const { history, location } = this.props;

    history.push(`${location.pathname}/email-sent`);
  }

  render() {
    const { translation } = this.props;

    return (
      <main className="page wrap">
        <div className="box box--sm box--solo box--form">
          <h1 className="box__title">
            {translation.heading}
          </h1>

          <p>{translation.body}</p>

          <form
            className="form"
            onSubmit={(e) => checkValidity(e, this.handleSubmit)}
            noValidate
          >
            <ul className="form__fields">
              <li>
                <label htmlFor="appointment-email">
                  {translation.email.label}
                </label>

                <input
                  type="email"
                  id="appointment-email"
                  data-errormsg={translation.email.error}
                  ref={(input) => this.email = input}
                  required
                  aria-required="true"
                />
              </li>
            </ul>

            <button
              className="btn"
              type="submit"
            >
              {translation.submit}
            </button>
          </form>
        </div>
      </main>
    )
  }
})

export default translate('AppointmentLookup')(AppointmentLookup);