import { action, observable } from 'mobx';
import { api } from '../config';

// AppStore (used for loading, error throwing, etc.)
import AppStore from './AppStore';

let obx = observable({
  /*-------------------------------------------
    Exams
  -------------------------------------------*/
  exams: [],

  /**
   * listExams - Gets exams for a course
   */
  listExams: action((dob = null, callback) => {
    AppStore.startLoading('listExams');

    const { courseSid, installSid } = AppStore.userData;
    let url = `/installs/${installSid}/courses/${courseSid}/exams`;

    if (dob) {
      url += `?dob=${dob}`;
    }

    api.get(url)
      .then(response => {
        obx.exams = response.data.results;

        if (callback) {
          callback();
        }

        AppStore.finishLoading('listExams');
      })
      .catch(error => {
        AppStore.throwError(error)
      });
  })
});

export default obx;
