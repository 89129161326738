/**
 * Renders a view that allows users to look up their appointment details
 */

import React from 'react';
import { observer } from 'mobx-react';

// Translation
import translate from '../../translate/Translate';

const EmailSent = observer(class EmailSent extends React.Component {
  componentDidMount() {
    // Set focus to the main page
    if(this.page) {
      this.page.focus();
    }
  }

  render() {
    const { translation } = this.props;

    return (
      <main
        className="page wrap"
        ref={(page) => this.page = page}
        tabIndex="-1"
        aria-labelledby="page-title"
      >
        <div className="box box--sm box--solo box--form">
          <h1 id="page-title" className="box__title">
            {translation.heading}
          </h1>

          <p>{translation.body}</p>
        </div>
      </main>
    )
  }
})

export default translate('EmailSent')(EmailSent);