/**
 * Renders the site header
 */

import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

const Header = observer(class Header extends React.Component {
  render() {
		const { domain, domainTitle } = this.props.store.DomainStore;

		if(domain) {
			return (
				<header id="header" className="site-head">
					<h1 className="meta">{domainTitle}</h1>

					<div className={'row-space-between'}>
						<Link
							className="brand"
							to={`/${domain}`}
						>
							<img
								className="brand__logo"
								src={`/static/img/logos/${domain}.png`}
								alt={`The ${domainTitle} logo`}
							/>
						</Link>
					</div>
				</header>
			)
		}

		return null;
  }
})

export default observer(Header);
